import React, { Component } from 'react';
import { connect } from 'react-redux';

import Layout from '../../components/layout';
import PromoBar from '../../components/promoBar';
import { withTranslation } from '../../lib/translate';
import './index.css';
import { isDefined, isEmptyObject, forwardTo, getSingleDeliveryOption } from '../../lib/utils';
import { getConfig } from '../../appConfig';
import { NormalText, Subtitle, Title } from '../../components/common';
import { getNews } from '../../store/actions';
import { IonSlide, IonSlides } from '@ionic/react';
import Modal from '../../components/modal';

const { hasOrdering, hasLoyalty, hasCampaignManager } = getConfig().appType;

class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      singleDelivery: getSingleDeliveryOption(),
      isFullScreenBannerOpened: false,
    };
  }
  componentDidMount() {
    this.props.dispatch(getNews());
  }
  openSliderFullImage = async (data) => {
    this.setState({
      selectedBanner: data,
      isFullScreenBannerOpened: true,
    });
  };

  render() {
    const { __, screenName, vouchers, orderHistory, news } = this.props;
    const { singleDelivery } = this.state;
    let voucherRes = (vouchers || []).filter((data) => {
      if (data.type === 1) {
        return data;
      }
    });
    const dashboardContent = getConfig().dashboardConfig;

    return (
      <Layout darkHeader={true} hideSecondToolbar={true} color="transparent" noPadding={true}>
        <div>
          {news.length > 0 && (
            <IonSlides
              options={{
                initialSlide: 0,
                initialSlide: 0,
                slidesPerView: 1,
                autoplay: {
                  loop: true,
                  disableOnInteraction: true,
                },
                loop: true,
              }}
            >
              {news.map((item, i) => (
                <IonSlide className={`slide-${i}`} key={i}>
                  <img src={item.banner_image} />
                </IonSlide>
              ))}
            </IonSlides>
          )}
        </div>
        <div
          className={`dashboard-promo-bars-wrapper ${orderHistory.length > 0 ? 'padded-top' : ''}`}
        >
          {hasCampaignManager ? <PromoBar type={__('info')} vouchers={voucherRes} /> : null}
          {/* {orderHistory.filter(
            (el) =>
              !el.is_gift &&
              el.status.toLowerCase() !== 'new' &&
              el.status.toLowerCase() !== 'created',
          ).length > 0 && (
            <HistoryOrderBar
              label={__('Order again')}
              handleClick={() => forwardTo('./history', { tab: 'order' })}
            />
          )} */}
        </div>
        <div className="dashboard-content">
          <div
            className="dashboard-header"
            style={orderHistory.length == 0 ? null : { paddingTop: '10px' }}
          >
            <Title>
              {__('Welcome')} {screenName}
            </Title>
            <NormalText>{__(dashboardContent?.description)}</NormalText>
          </div>
          <div className="dashboard-cards-wrapper">
            {dashboardContent?.data.map((item, i) => {
              return (
                <div key={i} className="dashboard-card " onClick={() => forwardTo(item.path)}>
                  <Subtitle key={i}>{__(item.title)}</Subtitle>
                </div>
              );
            })}
          </div>
        </div>
      </Layout>
    );
  }
}

const stateToProps = (state) => {
  const { profile, vouchers } = state.profile;
  const { orderHistory } = state.orders;
  const { news } = state.common;
  let screenName = '';
  if (
    (isDefined(profile) && !isEmptyObject(profile) && profile.first_name) ||
    profile.last_name ||
    (profile.first_name && profile.last_name)
  ) {
    screenName = `${profile.first_name}`;
  }
  return {
    screenName,
    vouchers: vouchers || [],
    orderHistory,
    news: news || [],
  };
};

export default connect(stateToProps)(withTranslation(Dashboard));
