import React from 'react';
import {
  IonList,
  IonItem,
  IonRadioGroup,
  IonRadio,
  IonLabel,
  IonNote,
  IonButtons,
  IonButton,
  IonAlert,
  IonIcon,
} from '@ionic/react';
import Loading from '../../components/spinner';
import Layout from '../../components/layout';
import {
  Title,
  Sectiontitle,
  SmallText,
  Spacer,
  NormalText,
  StrongText,
} from '../../components/common';
import { withTranslation } from '../../lib/translate';
import { updateProfile, removePaymentCard } from '../../store/actions';
import { connect } from 'react-redux';
import { isDefined, forwardTo, goBack } from '../../lib/utils';
import { getConfig } from '../../appConfig';
import './index.css';
import { cardOutline, removeCircleOutline } from 'ionicons/icons';

class Cards extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      removeCardAlert: null,
      selectPaymentCard: this.props.profile.cardToken || null,
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.profile.cardToken !== this.props.profile.cardToken) {
      this.setState({ selectPaymentCard: this.props.profile.cardToken });
    }
  }

  changeDefaultPaymentCard = (event) =>
    this.setState({ selectPaymentCard: event.detail.value }, () => {
      this.props.dispatch(updateProfile({ cardToken: event.detail.value }, true));
    });

  removePaymentCard = () => {
    const { __ } = this.props;
    this.props.dispatch(
      removePaymentCard(this.state.removeCardAlert, {
        __,
        cb: () => this.setState({ removeCardAlert: null }),
      }),
    );
  };

  handleRemoveCardAlert = (cardId) => this.setState({ removeCardAlert: cardId });

  backHandler = () => {
    if (
      this.props.location &&
      this.props.location.state &&
      this.props.location.state.addCardFromAccount
    ) {
      forwardTo('/account', { addCardFromAccount: true });
    } else {
      goBack();
    }
  };

  render() {
    const { __, cards } = this.props;
    const { removeCardAlert } = this.state;

    return (
      <Loading transparent>
        <Layout headerTitle={__('Your Credit Cards')} backHandler={this.backHandler}>
          <div className="flex-row-wrapper absolute-content">
            <div className="scrollable-y">
              <Title>{__('Your Payments')}</Title>
              <Spacer size={1} />
              <IonList className="cards" lines="none">
                {getConfig().payment === 'judopay' ? (
                  <IonRadioGroup
                    onIonChange={this.changeDefaultPaymentCard}
                    value={this.state.selectPaymentCard}
                  >
                    {cards.length > 0 ? (
                      cards.map((card) => {
                        const { id, last4, brand, exp_month, exp_year, name } = card;
                        return (
                          <IonItem lines="none" className="cards-list-card box-content " key={id}>
                            <div tabIndex="-1"></div>
                            <div className="cards-list-card-image">
                              <IonIcon icon={cardOutline}></IonIcon>
                            </div>
                            <IonLabel className="ion-text-wrap">
                              <NormalText className="single-item">{name}</NormalText>
                              <StrongText className="no-margin block">
                                **** **** **** {last4}
                              </StrongText>
                              <NormalText>
                                {__(brand)} - {exp_month}/{exp_year}
                              </NormalText>
                            </IonLabel>
                            {/* <IonRadio slot="start" value={id} /> */}
                            <IonButton
                              onClick={() => this.handleRemoveCardAlert(id)}
                              className="link"
                            >
                              <IonIcon icon={removeCircleOutline} />
                            </IonButton>
                          </IonItem>
                        );
                      })
                    ) : (
                      <IonItem lines="none">
                        <div
                          tabIndex="-1"
                          className="sectiontitle"
                          style={{ width: '100vh', textAlign: 'center' }}
                        >
                          {__('No payment cards')}
                        </div>
                      </IonItem>
                    )}
                  </IonRadioGroup>
                ) : (
                  <IonRadioGroup
                    onIonChange={this.changeDefaultPaymentCard}
                    value={this.state.selectPaymentCard}
                  >
                    {cards.length > 0 ? (
                      cards.map((card) => {
                        const { id, last4, brand, exp_month, exp_year, name } = card;
                        return (
                          <IonItem lines="none" className="cards-list-card box-content " key={id}>
                            <div tabIndex="-1"></div>
                            <div className="cards-list-card-image">
                              <IonIcon icon={cardOutline}></IonIcon>
                            </div>
                            <IonLabel className="ion-text-wrap">
                              <NormalText className="single-item">{name}</NormalText>
                              <StrongText className="no-margin block">
                                **** **** **** {last4}
                              </StrongText>
                              <NormalText>
                                {__(brand)} - {exp_month}/{exp_year}
                              </NormalText>
                            </IonLabel>
                            {/* <IonRadio slot="start" value={id} /> */}
                            <IonButton
                              onClick={() => this.handleRemoveCardAlert(id)}
                              className="link"
                            >
                              <IonIcon icon={removeCircleOutline} />
                            </IonButton>
                          </IonItem>
                        );
                      })
                    ) : (
                      <IonItem className="cards-list-card box-content no-cards-box">
                        <div className="cards-list-card-image">
                          <IonIcon icon={cardOutline}></IonIcon>
                        </div>
                        <IonLabel className="ion-text-wrap">
                          <NormalText className="single-item">
                            {__('No payment cards added')}
                          </NormalText>
                        </IonLabel>
                      </IonItem>
                    )}
                  </IonRadioGroup>
                )}
              </IonList>
              {/* <IonButton fill="clear" className="link" color="secondary" onClick={ () => forwardTo('/card-add', { ...this.props.location && this.props.location.state ? this.props.location.state : {}}) }>{ __('Add New Payment Card') }</IonButton> */}
            </div>
            <div className="flex-min">
              {/* <IonButton onClick={ () => forwardTo('/account', { ...this.props.location && this.props.location.state ? this.props.location.state : {}}) } expand="block" color="secondary">{ __('Done') }</IonButton> */}
              <IonButton
                expand="block"
                color="primary"
                onClick={() =>
                  forwardTo('/card-add', {
                    ...(this.props.location && this.props.location.state
                      ? this.props.location.state
                      : {}),
                  })
                }
              >
                {__('Add Payment Card')}
              </IonButton>
            </div>
          </div>
        </Layout>
        <IonAlert
          isOpen={isDefined(removeCardAlert)}
          onDidDismiss={() => this.handleRemoveCardAlert(null)}
          header={__('Confirm')}
          message={__('Do you you want to remove this card?')}
          buttons={[
            {
              text: __('Cancel'),
              role: 'cancel',
              cssClass: 'secondary',
            },
            {
              text: __('Remove'),
              handler: () => this.removePaymentCard(),
            },
          ]}
        />
      </Loading>
    );
  }
}

const mapStateToProps = (store) => {
  return {
    cards: store.orders.cards || [],
    profile: store.profile.profile || {},
  };
};

export default connect(mapStateToProps)(withTranslation(Cards));
