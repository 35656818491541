/* eslint-disable no-mixed-spaces-and-tabs */
import { IonButton, IonInput, IonItem } from '@ionic/react';
import moment from 'moment';
import React from 'react';
import { connect } from 'react-redux';
import { getConfig } from '../../appConfig';
import {
  FieldError /*, NormalText, SmallText*/,
  NormalText,
  SmallText,
  Subtitle,
} from '../../components/common';
import Modal from '../../components/modal';
// import plateImage from '../../assets/images/icons/eat.png'
import Basket from '../../lib/basket';
// import { close } from 'ionicons/icons'
import { withTranslation } from '../../lib/translate';
import { deepIsDefined, goBack, validateForm } from '../../lib/utils';
import { checkDiscount, sendVoucherCode, showToast } from '../../store/actions';
import './index.css';
import Layout from '../../components/layout';

class ApplyVouchers extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      discounts: null,
      selectedVoucher: null,
      showModal: null,
      voucherCode: '',
      disabledAppliedButton: false,
      formErrors: {
        voucherCode: '',
      },
    };
    this.formConfig = {
      voucherCode: { type: 'text', required: true },
    };
  }

  componentDidMount() {
    const { dispatch } = this.props;
    dispatch(checkDiscount(Basket.parseBasketForCheckVouchers()));
  }

  setVoucher = (voucher) => {
    this.setState({ selectedVoucher: voucher }, () => {
      const { vouchers, applicableVouchers } = this.props;
      const { selectedVoucher } = this.state;
      const appliedVoucher = vouchers.find((voucher) => voucher.id === selectedVoucher.id);
      const applicableVoucher = applicableVouchers.find((appliableVoucher) => {
        return getConfig().vouchersType === 1 || getConfig().vouchersType === 2
          ? applicableVouchers.find(
              (appliableVoucher) => appliableVoucher.id === selectedVoucher.id,
            )
          : appliableVoucher.type && appliableVoucher.type === 1
          ? appliableVoucher.id === selectedVoucher.id
          : appliableVoucher.id === selectedVoucher.reward.id;
      });
      if (Basket.canVoucherBeApplied(appliedVoucher, applicableVoucher)) {
        this.setState({ disabledAppliedButton: true });
      } else {
        this.setState({ disabledAppliedButton: false });
      }
    });
  };
  addVoucher = () => {
    const { selectedVoucher } = this.state;
    const { vouchers, applicableVouchers, dispatch, __ } = this.props;
    let appliedVoucher = [];
    let applicableVoucher = [];
    if (selectedVoucher && selectedVoucher.type) {
      appliedVoucher = vouchers.find(
        (voucher) => voucher.type && voucher.reward.id === selectedVoucher.reward.id,
      );
      applicableVoucher = applicableVouchers.find((appliableVoucher) => {
        return getConfig().vouchersType === 1 || getConfig().vouchersType === 2
          ? applicableVouchers.find(
              (appliableVoucher) => appliableVoucher.id === selectedVoucher.id,
            )
          : appliableVoucher.type && appliableVoucher.type === 1
          ? appliableVoucher.id === selectedVoucher.id
          : appliableVoucher.id === selectedVoucher.reward.id;
      });
    } else if (selectedVoucher) {
      appliedVoucher = vouchers.find((voucher) => voucher.id === selectedVoucher.id);
      applicableVoucher = applicableVouchers.find((appliableVoucher) =>
        appliableVoucher.type && appliableVoucher.type === 1
          ? appliableVoucher.id === selectedVoucher.id
          : appliableVoucher.id === selectedVoucher.reward.id,
      );
    }
    if (Basket.canVoucherBeApplied(appliedVoucher, applicableVoucher)) {
      this.setState({ selectedVoucher: null }, () => {
        Basket.addVoucher(appliedVoucher, applicableVoucher);
        goBack();
      });
    } else {
      dispatch(
        showToast(
          __('Your basket is higher then voucher cost. Please add more items in basket'),
          'warning',
        ),
      );
    }
  };

  showModal = (show) => {
    this.setState({ showModal: show });
    this.clearFields();
  };

  clearFields = () => {
    this.setState({ voucherCode: '' });
  };

  sendCode = () => {
    const { dispatch, __ } = this.props;
    let formErrors = validateForm(this.formConfig, this.state, __);
    this.setState({ formErrors });
    if (Object.keys(formErrors).length === 0) {
      const { voucherCode } = this.state;
      const data = { code: voucherCode.toUpperCase() };
      this.clearFields();
      dispatch(sendVoucherCode(data, true));
      this.showModal(false);
    }
  };

  filterVouchers = (vouchers, appliedVouchers) => {
    const { applicableVouchers, availableBalance } = this.props;
    const vouchersType = getConfig().vouchersType;
    let filteredVouchers = [];
    if (applicableVouchers && applicableVouchers.length > 0) {
      applicableVouchers.forEach((discount) => {
        let findedVoucher = vouchers.find((voucher) =>
          voucher && voucher.type === 1
            ? voucher.reward.id === discount.reward_sku
            : discount.type === 2
            ? voucher.reward.id === discount.id && availableBalance >= voucher.reward.cost
            : null,
        );
        if (vouchersType === 1) {
          if (findedVoucher) {
            filteredVouchers.push(findedVoucher);
          }
        } else if (vouchersType === 2) {
          if (findedVoucher && availableBalance > findedVoucher.cost) {
            filteredVouchers.push(findedVoucher);
          }
        } else {
          if (findedVoucher) {
            if (!findedVoucher.type && availableBalance >= findedVoucher.reward.cost) {
              filteredVouchers.push(findedVoucher);
            } else if (findedVoucher.type) {
              filteredVouchers.push(findedVoucher);
            }
          }
        }
      });
    }
    if (appliedVouchers && appliedVouchers.length > 0) {
      const ids = appliedVouchers.map((i) => i.id);
      return filteredVouchers.filter((filteredVoucher) => ids.indexOf(filteredVoucher.id) === -1);
    }
    return filteredVouchers;
  };
  render() {
    const { __, vouchers } = this.props;
    const { selectedVoucher, voucherCode, showModal, disabledAppliedButton } = this.state;
    const appliedVouchers = Basket ? Basket.applied_vouchers : [];
    const filteredVouchers = this.filterVouchers(vouchers, appliedVouchers);
    return (
      <Layout>
        <div className="flex-row-wrapper ">
          {/* {getConfig().vouchersType === 2 ? null : (
                <div className="flex-min">
                  <IonButton expand="block" color="primary" onClick={() => this.showModal(true)}>
                    {__('Voucher code')}
                  </IonButton>
                </div>
              )}
              <Spacer /> */}
          <div className="redeem-voucher-field-wrapper">
            <div className="input-field-container">
              <NormalText>{__('Enter voucher code')}</NormalText>
              <IonItem className="input-field-wrapper" lines="none">
                <IonInput
                  value={voucherCode}
                  onIonChange={(e) => this.setState({ voucherCode: e.target.value })}
                  placeholder={__('Voucher code')}
                />
              </IonItem>
            </div>
            <IonButton onClick={() => this.sendCode()} color="primary">
              {__('Submit')}
            </IonButton>
          </div>

          <Subtitle>{__('Apply vouchers')}</Subtitle>
          <div className="scrollable-y vaucher-list" style={{ marginTop: '10px' }}>
            {filteredVouchers.length === 0 ? (
              <NormalText>{__("You don't have any applicable vouchers in your wallet")}</NormalText>
            ) : (
              <NormalText>
                {__('The following vouchers from your wallet are applicable to this order.')}
              </NormalText>
            )}
            {filteredVouchers.length > 0 &&
              filteredVouchers.map((filteredVoucher, index) => {
                return (
                  <div
                    className={
                      selectedVoucher && selectedVoucher.id === filteredVoucher.id
                        ? 'selected-voucher-box'
                        : ''
                    }
                    onClick={() => {
                      this.setVoucher(filteredVoucher);
                    }}
                  >
                    <div
                      style={{ backgroundImage: `url(${filteredVoucher.reward.image})` }}
                      className={
                        selectedVoucher && selectedVoucher.id === filteredVoucher.id
                          ? 'voucher apply-voucher'
                          : 'voucher apply-voucher-box'
                      }
                    ></div>
                    <div className="voucher-description">
                      <SmallText>{__(filteredVoucher.reward.name)}</SmallText>
                      <SmallText>{`${__('Expires')} ${moment(
                        filteredVoucher.reward.expiry_date,
                        'YYYY/MM/DD',
                      ).format('DD/MM/YY')}`}</SmallText>
                    </div>
                    {filteredVoucher.id == this.state.selectedVoucher?.id && (
                      <IonButton
                        onClick={() => {
                          this.addVoucher();
                        }}
                        className="apply-voucher-to-order-btn"
                      >
                        {__('Apply Voucher to Order')}
                      </IonButton>
                    )}
                  </div>
                );
              })}
          </div>
          {/* {filteredVouchers.length > 0 && (
						<div className="flex-min">
							<IonButton
								color="primary"
								disabled={selectedVoucher ? false : true}
								onClick={() => {
									this.addVoucher();
								}}
								className={disabledAppliedButton ? 'box-button-color-tertiary' : 'box-button-color'}
								size="full"
								shape="round"
							>
								{__('Apply Vouchers')}
							</IonButton>
						</div>
					)} */}
        </div>
        <Modal
          className="apply-voucher-modal"
          isOpen={!!showModal}
          onDidDismiss={() => this.setState({ showModal: null })}
          title={__('Enter code')}
        >
          <div className="flex-row-wrapper">
            <div className="centered flex-row-wrapper">
              <div className=" centered scrollable-y">
                <NormalText>
                  {__('Enter a valid voucher code below to receive your reward')}
                </NormalText>
                <div className="lefted padded voucher-item">
                  <IonItem style={{ '--min-height': '32px' }}>
                    <IonInput
                      value={voucherCode}
                      onIonChange={(e) => this.setState({ voucherCode: e.target.value })}
                      placeholder={__('Voucher code')}
                    />
                  </IonItem>
                  <FieldError
                    className="field-error"
                    value={__(this.state.formErrors.voucherCode)}
                  />
                </div>
              </div>
            </div>
            <div className="flex-min">
              <IonButton onClick={() => this.sendCode()} expand="full" color="primary">
                {__('Submit')}
              </IonButton>
            </div>
          </div>
        </Modal>
      </Layout>
    );
  }
}

const mapStateToProps = (store) => {
  return {
    availableBalance: deepIsDefined(store, 'profile.profile.available_balance')
      ? store.profile.profile.available_balance
      : 0,
    basketUpadated: store.orders.basketUpdated,
    vouchers: store.profile.vouchers || null,
    applicableVouchers: store.profile.applicableVouchers || null,
  };
};

export default connect(mapStateToProps)(withTranslation(ApplyVouchers));
