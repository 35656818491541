import { IonAlert, IonButton, IonIcon, IonItem, IonModal } from '@ionic/react';
import { close } from 'ionicons/icons';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { getConfig } from '../../appConfig';
import { withTranslation } from '../../lib/translate';
import { validateProfileData } from '../../lib/utils';
import { setModal, updateProfile, validateEmail } from '../../store/actions';
import { NormalText, Spacer, Title } from '../common';
import ValidateInput from '../validateInput';
import './index.css';

class ValidateModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      first_name: this.props.profile.first_name || '',
      last_name: this.props.profile.last_name || '',
      email: this.props.profile.email || '',
      birthday: this.props.profile.birthday ? this.props.profile.birthday : '',
      saveAccountIsOpen: false,
      // email_verified: false
    };
    this.handleInput = this.handleInput.bind(this);
    this.handleSave = this.handleSave.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (this.props.profile.first_name !== prevProps.profile.first_name) {
      this.setState({ first_name: this.props.profile.first_name });
    }
    if (this.props.profile.last_name !== prevProps.profile.last_name) {
      this.setState({ last_name: this.props.profile.last_name });
    }
    if (this.props.profile.email !== prevProps.profile.email) {
      this.setState({ email: this.props.profile.email });
    }
    if (this.props.profile.birthday !== prevProps.profile.birthday) {
      this.setState({
        birthday: this.props.profile.birthday ? this.props.profile.birthday : '',
      });
    }
    if (this.props.profile.email_verified !== prevProps.profile.email_verified) {
      this.setState({ email_verified: this.props.profile.email_verified });
    }
  }

  handleSave(fromAlert = false) {
    const { dispatch } = this.props;
    const { first_name, last_name, birthday, email, email_verified } = this.state;
    const profile = {
      first_name: first_name,
      last_name: last_name,
      birthday: birthday,
      email: email,
      email_verified: email_verified,
    };
    if (
      (!this.props.profile.birthday || this.props.profile.birthday == '') &&
      !fromAlert &&
      birthday !== ''
    ) {
      this.setState({ saveAccountIsOpen: true });
      return;
    }
    dispatch(updateProfile(profile, true));
    dispatch(setModal('isVerfiedModalOpen', false));
  }

  handleInput = (key, val) => {
    this.setState({ [key]: val });
  };

  handleValidateEmail = () => {
    const { dispatch } = this.props;
    const { first_name, last_name, birthday, email, email_verified } = this.state;
    const profile = {
      first_name: first_name,
      last_name: last_name,
      birthday: birthday,
      email: email,
      email_verified: email_verified,
    };
    dispatch(validateEmail(profile));
  };

  render() {
    const { __, isVerfiedModalOpen, dispatch, profile } = this.props;
    const {
      first_name,
      last_name,
      birthday,
      email,
      email_verified,
      saveAccountIsOpen,
    } = this.state;
    const dateFormat = 'dd-mm-yy';
    const validatedData = validateProfileData({ ...profile, ...this.state });
    const yesterday = new Date(new Date().setDate(new Date().getDate() - 1));
    return (
      <>
        <IonModal
          cssClass="validate-modal"
          isOpen={isVerfiedModalOpen}
          onDidDismiss={() => dispatch(setModal('isVerfiedModalOpen', false))}
        >
          <div
            className="validate-modal-closer"
            onClick={() => dispatch(setModal('isVerfiedModalOpen', false))}
          >
            <IonIcon icon={close} mode="ios" />
          </div>
          <div className="validate-modal-header">
            <Title className="centered spaced-title">{__('Verify')}</Title>
            <NormalText className="centered block">
              {__('To verify your account, complete the highlighted details below:')}
            </NormalText>
            <Spacer size={1} />
          </div>

          <div className="modal-content">
            <div className="input-field-container">
              <NormalText>{__('First Name')}</NormalText>
              <IonItem lines="none" className="input-field-wrapper">
                <ValidateInput
                  label="First Name"
                  type="text"
                  onIonChange={(e) => this.handleInput('first_name', e.target.value)}
                  name="first_name"
                  validatedData={validatedData}
                  value={first_name}
                />
              </IonItem>
            </div>
            <div className="input-field-container">
              <NormalText>{__('Last Name')}</NormalText>
              <IonItem lines="none" className="input-field-wrapper">
                <ValidateInput
                  label="Last Name"
                  type="text"
                  onIonChange={(e) => this.handleInput('last_name', e.target.value)}
                  name="last_name"
                  validatedData={validatedData}
                  value={last_name}
                />
              </IonItem>
            </div>
            <div className="input-field-container">
              <NormalText>{__('Date Of Birth')}</NormalText>
              <IonItem
                lines="none"
                className={`input-field-wrapper ${
                  profile.birthday !== null ? 'disabled-wrapper' : ''
                }`}
              >
                <ValidateInput
                  type="date"
                  onIonChange={(e) => this.handleInput('birthday', e.target.value)}
                  name="birthday"
                  dateFormat={dateFormat}
                  validatedData={validatedData}
                  value={birthday}
                  max={yesterday}
                />
              </IonItem>
            </div>
            <div className="input-field-container">
              <NormalText>{__('Email')}</NormalText>
              <IonItem lines="none" className="input-field-wrapper">
                {' '}
                <ValidateInput
                  label="Email Address"
                  type="email"
                  onIonChange={(e) => this.handleInput('email', e.target.value)}
                  name="email"
                  validatedData={validatedData}
                  value={email}
                />
              </IonItem>
            </div>
            <div className="input-field-container">
              <NormalText>{__('Email Address Verified')}</NormalText>
              <IonItem
                lines="none"
                className="input-field-wrapper"
                onClick={() => {
                  !validatedData.email_verified && this.handleValidateEmail();
                }}
              >
                <ValidateInput
                  type="email_verified"
                  onIonChange={(e) => this.handleInput('email_verified', e.target.value)}
                  name="email_verified"
                  validatedData={validatedData}
                  value={email_verified}
                />
              </IonItem>
            </div>
          </div>
          <div className="top-medium">
            <IonButton
              expand="block"
              color="primary"
              className="validation "
              onClick={() => this.handleSave()}
            >
              {__('Save')}
            </IonButton>
          </div>
        </IonModal>
        <IonAlert
          isOpen={saveAccountIsOpen}
          onDidDismiss={() => this.setState({ saveAccountIsOpen: false })}
          header={__('Save Your Account')}
          message={__(
            "Please double check your date of birth. You will not be able to change this once it's set",
          )}
          buttons={[
            {
              text: __('Cancel'),
              role: 'cancel',
              cssClass: 'secondary',
              handler: () => this.setState({ saveAccountIsOpen: false }),
            },
            {
              text: __('OK'),
              handler: () => this.handleSave(true),
            },
          ]}
        />
      </>
    );
  }
}

const stateToProps = (store) => {
  const { isVerfiedModalOpen, profile } = store.profile;
  return {
    isVerfiedModalOpen,
    profile,
  };
};

export default connect(stateToProps)(withRouter(withTranslation(ValidateModal)));
