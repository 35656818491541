export const REQUEST_ERROR = 'REQUEST_ERROR';
export const CLEAR_ERROR = 'CLEAR_ERROR';
export const LOADING = 'LOADING';
export const ERROR = 'ERROR';
export const SEND_FIREBASE_TOKEN = 'SEND_FIREBASE_TOKEN';
export const SET_COMMON_PROP = 'SET_COMMON_PROP';
export const SHOW_TOAST = 'SHOW_TOAST';
export const RESET_TOAST = 'RESET_TOAST';
export const REMOVE_TOAST = 'REMOVE_TOAST';
export const INIT = 'INIT';
export const GET_SOCIALS = 'GET_SOCIALS';
export const SEND_FEEDBACK = 'SEND_FEEDBACK';
export const SET_COMMON_MODAL = 'SET_COMMON_MODAL';
export const GET_TERMS = 'GET_TERMS';
export const GET_PRIVACY_POLICY = 'GET_PRIVACY_POLICY';
export const GET_FAQ = 'GET_FAQ';
export const INIT_FIREBASE = 'INIT_FIREBASE';
export const CHANGE_CONNECTIONS_STATUS = 'CHANGE_CONNECTIONS_STATUS';
export const CLEAR_INTERVAL = 'CLEAR_INTERVAL';
export const LOCATION = 'LOCATION';
export const INIT_FIREBASE_DATABASE = 'INIT_FIREBASE_DATABASE';
export const GET_ORDER_PRODUCTION_MINS = 'GET_ORDER_PRODUCTION_MINS';
export const GET_NEWS = 'GET_NEWS';
export const SET_NEWS = 'SET_NEWS';
