import React from 'react';
import { withRouter } from 'react-router';
import {
  IonHeader,
  IonToolbar,
  IonMenuButton,
  IonButtons,
  IonButton,
  IonIcon,
  IonBadge,
} from '@ionic/react';
import { scan, basket, arrowBack, search } from 'ionicons/icons';
import { connect } from 'react-redux';
import Basket from '../../lib/basket';
import Icon from '../icon';
import { SmallText, Title } from '../common';
import { forwardTo, getRouteClassName, isWebConfig, goBack } from '../../lib/utils';
import { withTranslation } from '../../lib/translate';
import { getConfig } from '../../appConfig';
import './index.css';
import BackButton from '../backButton';
const logo = require('../../assets/images/logo-main.png');
const logoWhite = require('../../assets/images/logo-white.png');
const StaticHeader = ({
  __,
  history,
  headerWithTitle,
  title,
  hasSearchIcon,
  onSearchHandler,
  searchActive,
  showHamburger,
  backHandler,
  darkHeader,
}) => {
  const currentPath = history.location.pathname;
  // const currPath = history.location.pathname.split('/').join('')
  // const isDisabled = currPath === 'delivery-options' ? true : false
  const config = getConfig();
  const authPages = config.general.authRoutes.indexOf(currentPath) !== -1;
  const hideStaticHeader = config.theme.routesWithoutStaticHeader.indexOf(currentPath) !== -1;
  const specificDashboardTransparentedHeader =
    currentPath === '/dashboard' ? 'primary-toolbar--dashboard' : '';
  const specificRafTransparentedHeader =
    currentPath === '/refer-a-friend' ? 'primary-toolbar--refer-a-friend' : '';
  const specificDeliveryOptionsTransparentedHeader =
    currentPath === '/delivery-options' ? 'primary-toolbar--delivery-options' : '';
  // const isLogin = currentPath === '/login'
  const routeClassName = getRouteClassName(currentPath);
  const pagesWithoutBackButton =
    getConfig().general.routesWithoutBackButton.indexOf(currentPath) !== -1;

  return (
    <>
      {hideStaticHeader ? null : config.theme.showHeaderOnAuthRoutes || !authPages ? (
        <IonHeader className={'static ' + routeClassName}>
          <IonToolbar
            className={`primary-toolbar ${specificDashboardTransparentedHeader} ${specificRafTransparentedHeader} ${specificDeliveryOptionsTransparentedHeader}`}
          >
            {/* <IonButtons slot="start">
              {
                !authPages ? (
                  <IonMenuButton />
                ) : authPages && !isWebConfig() ? (
                  <IonButton onClick={() => goBack()}>
                    <Icon icon={arrowBack} classname="icon" />
                  </IonButton>
                ) : null
              }
            </IonButtons> */}
            <IonButtons slot="start">
              {!authPages && (pagesWithoutBackButton || showHamburger) ? (
                <IonMenuButton color={darkHeader ? 'white' : 'dark'} />
              ) : (
                <>
                  <BackButton color={darkHeader ? 'white' : 'dark'} backHandler={backHandler} />
                </>
              )}
            </IonButtons>
            {!headerWithTitle ? (
              <IonButtons className="image-button-wrapper" color="primary">
                <IonButton
                  style={{
                    backgroundImage: `url(${darkHeader ? logoWhite : logo})`,
                    backgroundSize: 'auto 100%',
                    backgroundPosition: 'center',
                    backgroundRepeat: 'no-repeat',
                  }}
                  className="image-button"
                  color="primary-shade"
                  onClick={() => forwardTo('/dashboard')}
                />
              </IonButtons>
            ) : (
              <IonButton
                color="transparent"
                className="header-title"
                onClick={() => forwardTo('/dashboard')}
              >
                <Title className={`primary-header-title`}>{title}</Title>
              </IonButton>
            )}
            <IonButtons slot="end" className={`${searchActive ? 'button-active' : ''}`}>
              {hasSearchIcon ? (
                <>
                  <IonButton
                    className="header-search"
                    button
                    clear
                    onClick={() => onSearchHandler()}
                  >
                    <IonIcon color="secondary" slot="icon-only" icon={search} />
                  </IonButton>
                </>
              ) : (
                <>
                  {Basket.itemsCount() > 0 || isWebConfig() ? (
                    <IonButton
                      disabled={Basket.itemsCount() > 0 ? false : true}
                      color="dark"
                      className="basket-button"
                      onClick={() => forwardTo('/order-summary')}
                      size={24}
                    >
                      <div>
                        <div className="basket-icon-wrapper">
                          <IonBadge
                            slot="end"
                            color="primary"
                            className={Basket.itemsCount() >= 10 ? 'badge' : 'badge-small'}
                          >
                            {Basket.itemsCount()}
                          </IonBadge>
                          <Icon
                            color={darkHeader ? 'white' : 'dark'}
                            icon={basket}
                            classname="icon"
                          />
                        </div>
                        <div>
                          <SmallText color={darkHeader ? 'white' : 'dark'}>
                            {Basket._getTotal()}
                          </SmallText>
                        </div>
                      </div>
                    </IonButton>
                  ) : !authPages && !isWebConfig() ? (
                    config.appType.hasLoyalty ? (
                      <IonButton
                        color={darkHeader ? 'white' : 'dark'}
                        button
                        clear
                        onClick={() => forwardTo('/loyalty', { tab: 'scan' })}
                      >
                        <IonIcon slot="icon-only" icon={scan} />
                      </IonButton>
                    ) : null
                  ) : null}
                </>
              )}
            </IonButtons>
          </IonToolbar>
        </IonHeader>
      ) : null}
    </>
  );
};

const stateToProps = (store) => {
  const { orders } = store;
  return {
    basketUpdated: orders.basketUpdated,
  };
};

export default connect(stateToProps)(withRouter(withTranslation(StaticHeader)));
