import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { getConfig } from '../../appConfig';
import Layout from '../../components/layout';
import { withTranslation } from '../../lib/translate';
import { isDefined } from '../../lib/utils';
import { ApplyPoints } from '../applyPoints';
import ApplyVouchers from '../applyVouchers';
import './index.css';

class ApplyLoyalty extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    const { __, profile } = this.props;
    const points_value_percentage =
      isDefined(getConfig().api_config) && isDefined(getConfig().api_config.points_value_percentage)
        ? getConfig().api_config.points_value_percentage
        : 0;

    return (
      <Layout
        hideSecondToolbar={true}
        headerTitle={__('Redeem Loyalty')}
        color="transparent"
        scrollY={false}
      >
        <div className="absolute-content scrollable-y">
          {!(points_value_percentage == 0 && profile.available_balance == 0) && (
            <ApplyPoints></ApplyPoints>
          )}
          {/* <Spacer /> */}
          <ApplyVouchers></ApplyVouchers>

          {/* <RedeemGiftVoucher __={__} /> */}
        </div>
      </Layout>
    );
  }
}

const stateToProps = (state) => {
  return {
    transactionHistory: state.orders.history || [],
    orderHistory: state.orders.orderHistory || [],
    profile: state.profile.profile,
  };
};

export default withRouter(withTranslation(connect(stateToProps)(ApplyLoyalty)));
