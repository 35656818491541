import { IonButton } from '@ionic/react';
import React from 'react';
import { connect } from 'react-redux';
import { getConfig } from '../../appConfig';
import { NormalText, Spacer, Subtitle } from '../../components/common';
import Incrementer from '../../components/incrementer';
import Layout from '../../components/layout';
import Loading from '../../components/spinner';
import Basket from '../../lib/basket';
import BigNumber from '../../lib/bignumber';
import { withTranslation } from '../../lib/translate';
import { deepIsDefined, forwardTo, isDefined, isWebConfig } from '../../lib/utils';
import './index.css';

class ApplyPointsRaw extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      points: this.getDefaultPoints(),
    };
  }
  getDefaultPoints = () => {
    const minimumPoints = this.getRedeemPointsMin();
    const availableBalance = this.props.availableBalance;
    const orderValue =
      new BigNumber(Basket.getTotal()).times(100).toNumber() + Basket.getAppliedPoints();
    let defaultPoints = 0;
    if (availableBalance >= minimumPoints && orderValue >= minimumPoints) {
      defaultPoints = availableBalance > orderValue ? orderValue : availableBalance;
    }
    return defaultPoints;
  };
  getRedeemPointsMin = () => parseInt(getConfig().general.redeemPointsMin) || 50;

  componentDidMount() {
    const points = Basket.getAppliedPoints();
    if (points > 0) {
      this.setState({ points });
    }
  }

  onIncrementerUpdate = (points) => {
    // from min to 0
    if (points < this.state.points && points < this.getRedeemPointsMin()) {
      points = 0;
    }
    // from 0 to min
    if (points > this.state.points && points < this.getRedeemPointsMin()) {
      points = this.getRedeemPointsMin();
    }
    this.setState({ points });
  };

  applyPoints = () => {
    Basket.applyPoints(this.state.points, this.props.availableBalance, () => {
      if (isWebConfig()) {
        forwardTo('/order');
      } else {
        forwardTo('/order-summary');
      }
    });
  };

  render() {
    const { __ } = this.props;
    let availableBalance = this.props.availableBalance;
    const { points } = this.state;
    const step = getConfig().general.redeemPointsStep || 50;

    const basketTotalInCents =
      new BigNumber(Basket.getTotal()).times(100).toNumber() + Basket.getAppliedPoints();
    let limit = 0;
    if (!isDefined(availableBalance) && availableBalance === null) {
      availableBalance = 0;
    } else {
      limit = parseInt(availableBalance / step) * step;
    }

    // basket total: 5.2$ => 520 => 520/step(e.g. step=100)=5.2 => int(5.2) = 5 => 5 * step(e.g. step=100)
    // limit                    - represents available balance limit
    // pointsLimitBasedOnBasket - represents limit based on basket value
    // we will use lower value
    let pointsLimitBasedOnBasket = basketTotalInCents;
    if (pointsLimitBasedOnBasket > 0) {
      if (basketTotalInCents <= availableBalance) {
        // if user have enough points to cover entire basket total then we should let him to go over step
        limit = basketTotalInCents;
      } else {
        pointsLimitBasedOnBasket = parseInt(pointsLimitBasedOnBasket / step) * step;
      }
    }

    return (
      <div className="apply-points-wrapper">
        <div className="apply-points-content box-content" style={{ marginTop: '10px' }}>
          <div className="wrap-small-text">
            <NormalText className="primary-regular ">
              {__('Great news! You have')}{' '}
              <strong>
                {availableBalance} {__('loyalty points')}
              </strong>
              {!getConfig().flags.removeLoyaltyPointsWorth && (
                <>
                  {' '}
                  {__('available worth')}{' '}
                  <strong>
                    {Basket._calculatePointsAppliedPrice(availableBalance, false, true)}
                  </strong>
                </>
              )}
              . {__('How many points would you want to redeem') + '?'}
            </NormalText>
          </div>
          <Spacer size={2} />
          <Incrementer
            maxLimit={this.getDefaultPoints()}
            onUpdate={this.onIncrementerUpdate}
            quantity={points}
            step={step}
            allowNegative={false}
            note={`${__('Redeem')} ${points} ${__('Loyalty points')}`}
            minLimit={0}
          />
          <Spacer size={1} />
          <IonButton
            disabled={points === 0 ? false : points < this.getRedeemPointsMin()}
            expand="block"
            onClick={this.applyPoints}
            color="primary"
          >
            {__('Redeem')} {points} {__('loyalty points')}
          </IonButton>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (store) => {
  return {
    availableBalance: deepIsDefined(store, 'profile.profile.available_balance')
      ? store.profile.profile.available_balance
      : 0,
    basketUpadated: store.orders.basketUpdated,
  };
};

export const ApplyPoints = connect(mapStateToProps)(withTranslation(ApplyPointsRaw));

const ApplyPointsWrapped = (props) => (
  <Loading>
    <Layout color="transparent" headerTitle={props.__('Redeem Points')}>
      <ApplyPoints {...props} />
    </Layout>
  </Loading>
);

export default withTranslation(ApplyPointsWrapped);
