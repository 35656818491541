import React from 'react';
import { IonItem, IonInput, IonButton, IonIcon } from '@ionic/react';
import { connect } from 'react-redux';
import { lockClosed, mail } from 'ionicons/icons';
import { getConfig } from '../../appConfig';
import Layout from '../../components/layout';
import PasswordInput from '../../components/passwordInput';
import { forwardTo, getDefaultRoute } from '../../lib/utils';
import { loginRequest } from '../../store/actions';
import { withTranslation } from '../../lib/translate';
import Loading from '../../components/spinner';
import { FieldError, Title, Spacer, NormalText } from '../../components/common';
import { validateForm } from '../../lib/utils';
import './index.css';

class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      username: '',
      password: '',
      formErrors: {},
    };
    this.handleInput = this.handleInput.bind(this);
    this.handleLogin = this.handleLogin.bind(this);
    this.formConfig = {
      username: { type: 'email', required: true },
      password: { type: 'password', required: true },
    };
  }

  handleInput(key, val) {
    this.setState({ [key]: val });
  }

  handleLogin() {
    let formErrors = validateForm(this.formConfig, this.state);
    this.setState({ formErrors });
    if (Object.keys(formErrors).length === 0) {
      const { protectedReferrer } = this.props;
      const { username, password } = this.state;
      this.props.dispatch(loginRequest({ username, password, referrer: protectedReferrer }));
    }
  }

  checkLoginStatus = () => {
    const { loggedIn } = this.props.auth;
    if (loggedIn) {
      const defaultRoute = getDefaultRoute();
      forwardTo(defaultRoute.path);
    }
  };

  componentDidUpdate() {
    this.checkLoginStatus();
  }

  componentDidMount() {
    this.checkLoginStatus();
  }

  render() {
    const { __ } = this.props;
    const { username, password } = this.state;
    return (
      <Loading transparent>
        <Layout
          headerTitle={__('Login')}
          hideBack={true}
          color="transparent"
          contentClassName="login-background"
        >
          <div className="absolute-content flex-row-wrapper">
            <Title>{__('Sign in')}</Title>
            <Spacer size={1} />

            <div>
              <div className="input-field-container ">
                <IonItem lines="none" className="input-field-wrapper">
                  <IonInput
                    placeholder={__('Email address')}
                    onIonChange={(e) => this.handleInput('username', e.target.value)}
                    onIonBlur={(e) => this.handleInput('username', e.target.value)}
                    clearInput
                    type="email"
                    pattern="email"
                    inputmode="email"
                    value={username}
                  />
                </IonItem>
              </div>

              <FieldError className="field-error" value={__(this.state.formErrors.username)} />

              <div className="input-field-container ">
                <IonItem lines="none" className="input-field-wrapper">
                  <PasswordInput
                    placeholder={__('Password')}
                    __={__}
                    onIonChange={(e) => this.handleInput('password', e.target.value)}
                    value={password}
                  />
                </IonItem>
              </div>
              <FieldError className="field-error" value={__(this.state.formErrors.password)} />
              <Spacer size={4} />
              <IonButton expand="block" color="primary" onClick={() => this.handleLogin()}>
                {__('Sign in')}
              </IonButton>
              <IonButton
                expand="block"
                fill="clear"
                color="secondary"
                className="link underlined"
                onClick={() => forwardTo('/reset-password')}
              >
                {__('Forgot password')}
              </IonButton>
              <div className="block-separator">
                <NormalText>{__('or')}</NormalText>
              </div>
              <NormalText className="centered block">{__('Don’t have an account?')}</NormalText>
              <IonButton
                expand="block"
                color="secondary"
                fill="clear"
                className="link underlined"
                onClick={() => forwardTo('/register')}
              >
                {__('Sign up')}
              </IonButton>
            </div>
          </div>
        </Layout>
      </Loading>
    );
  }
}

const stateToProps = (state) => {
  const { auth, protectedReferrer } = state.profile;
  return {
    auth,
    protectedReferrer,
  };
};

export default connect(stateToProps)(withTranslation(Login));
