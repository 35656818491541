import {
  IonButton,
  IonCheckbox,
  IonIcon,
  IonInput,
  IonItem,
  IonLabel,
  IonRadio,
  IonRadioGroup,
} from '@ionic/react';
import { close, search } from 'ionicons/icons';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getConfig } from '../../appConfig';
import { NormalText, Sectiontitle, SmallText, StrongText, Title } from '../../components/common';
import Layout from '../../components/layout';
import Modal from '../../components/modal';
import Loading from '../../components/spinner';
import Basket from '../../lib/basket';
import moment from '../../lib/moment';
import { withTranslation } from '../../lib/translate';
import { deepCopy, forwardTo, isDefined, isEmptyObject, makeKey } from '../../lib/utils';
import { setDeliveryOption, setScrollTop } from '../../store/actions';
import { ItemDetailsRaw } from '../itemDetails';
import '../order/index.css';
import { OrderSummary } from '../orderSummary';
import './index.css';
import OrderList from './orderList';
const logo = require('../../assets/images/logo-main.png');

const {
  validateItem,
  getProductName,
  getProductDescription,
  _calculateItemPrice,
  formatPrice,
  addToBasket,
  isChoicesGroupValid,
} = Basket;

const defaultModalItemOptions = {
  quantity: 1,
  price: 0,
  selectedChoices: [],
  instructions: '',
};

// category menu calc
const rowHeight = 41; // <-- within error of 1 or 2 pixels
const topOffset = 50;

class OrderPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedCategory: 0,
      ikentooMenu: null,
      originalMenu: null,
      searchItemsValue: '',
      menuRefs: null,
      categoriesPositionTop: [],
      modalOpen: false,
      modalItem: null,
      modalItemOptions: defaultModalItemOptions,
      validationErrors: [],
      showValidationAlert: false,
      previousRowNum: 1,

      mainLogo: '',
      nameVisible: true,
      categoryHeight: 0,
    };
    this.selectCategoryOnScroll = this.selectCategoryOnScroll.bind(this);
  }

  setIkentooMenu = (menu) => {
    this.setState(
      {
        ikentooMenu:
          menu || (Basket.items.length === 0 && isEmptyObject(this.props.ikentooMenu))
            ? this.props.defaultMenu
            : this.props.ikentooMenu,
        originalMenu:
          menu || (Basket.items.length === 0 && isEmptyObject(this.props.ikentooMenu))
            ? this.props.defaultMenu
            : this.props.ikentooMenu,
      },
      () => {
        let menuRefs = this.menuRefs(this.state.ikentooMenu);
        this.setState({ menuRefs });
      },
    );
  };
  setDeliveryOption = (delivery) => {
    this.props.dispatch(setDeliveryOption(delivery));
    Basket.setDeliveryOption(delivery);
    Basket.setOrderType(delivery.id);
    forwardTo(
      delivery.id === 'delivery'
        ? '/delivery'
        : delivery.id === 'charter-delivery'
        ? '/delivery'
        : delivery.id === 'pick-up-point'
        ? '/pick-up-point'
        : delivery.id === 'table'
        ? '/order-to-table'
        : '/click-and-collect',
      { isLogoClicked: false },
    );
  };

  onWindowResize = () => {
    setTimeout(() => {
      const catMenu = document.querySelector('.order-categories-inner');
      if (catMenu) {
        const rowNum = Math.round(catMenu.clientHeight / rowHeight);
        if (rowNum !== this.state.previousRowNum) {
          this.setState({ previousRowNum: rowNum });
          const subHeaders = document.querySelectorAll('.order-sublist-header');
          subHeaders.forEach((item) => (item.style.top = topOffset + rowNum * rowHeight + 'px'));
        }
      }
    }, 0);
  };
  componentDidUpdate(prevProps, prevState) {
    if (this.state.categoryHeight !== document.querySelector('.order-categories')?.clientHeight) {
      this.setState({ categoryHeight: document.querySelector('.order-categories')?.clientHeight });
    }
  }
  componentDidMount() {
    this.setIkentooMenu();
    window.addEventListener('resize', this.onWindowResize, false);
    setTimeout(() => {
      this.onWindowResize();
      const item = document.querySelector('.no-padding');
      if (item) {
        item.scrollTop = this.props.scrollTop;
        item.scrollBy({ top: 1, behavior: 'smooth' });
      }
    }, 1500);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.onWindowResize);
  }

  shouldComponentUpdate(nextProps) {
    const prevMenuName = (Basket.items.length === 0 && isEmptyObject(this.props.ikentooMenu)
      ? nextProps.defaultMenu
      : nextProps.ikentooMenu || {}
    ).menuName;
    const currentMenuName = (Basket.items.length === 0 && isEmptyObject(this.props.ikentooMenu)
      ? this.props.defaultMenu
      : this.props.ikentooMenu || {}
    ).menuName;
    if (
      prevMenuName !== currentMenuName ||
      nextProps.restaurantsUpdated !== this.props.restaurantsUpdated
    ) {
      this.setIkentooMenu();
    }
    return true;
  }

  menuRefs = (menuRefs) => {
    return menuRefs && menuRefs.menuEntryGroups
      ? menuRefs.menuEntryGroups.reduce((acc, value, index) => {
          let name = value.name + '_' + index;
          acc[name] = React.createRef();
          return acc;
        }, {})
      : {};
  };

  scrollTo = (name) => {
    let categoryHeightHeading = document.querySelector('.order-list-heading');
    let categoryHeightHeadingHeight = 0;
    if (categoryHeightHeading) {
      categoryHeightHeadingHeight = categoryHeightHeading.clientHeight;
    }
    document.querySelector('.no-padding').scroll({
      top: this.state.menuRefs[name].current.offsetTop + categoryHeightHeadingHeight,
      behavior: 'smooth',
    });
  };

  selectCategoryOnScroll = (e) => {
    e.preventDefault();
    const headingInfo = document.querySelector('.order-list-restaurant-image');
    const positionTop =
      headingInfo && e.target.scrollTop - headingInfo.clientHeight > 0
        ? e.target.scrollTop - headingInfo.clientHeight
        : e.target.scrollTop;
    const { menuRefs } = this.state;
    setTimeout(() => {
      const positions = Object.keys(menuRefs).map((key) => {
        if (!menuRefs[key].current) {
          return null;
        }
        return menuRefs[key].current.offsetTop;
      });
      let selectCategory = 0;
      positions.forEach((item, i) => {
        if (item <= positionTop + 350) {
          selectCategory = i;
        }
      });
      this.props.dispatch(setScrollTop(positionTop));
      this.setState({ selectedCategory: selectCategory });
    }, 150);
  };

  showModal = (modalItem) => {
    this.setState(
      {
        modalItem,
        modalItemOptions: {
          ...this.state.modalItemOptions,
          price: _calculateItemPrice({ item: modalItem, quantity: 1 }),
          selectedChoices:
            modalItem.menuDealGroups && modalItem.menuDealGroups.length
              ? Array(modalItem.menuDealGroups.length).fill([])
              : [],
          validationErrors:
            modalItem.menuDealGroups && modalItem.menuDealGroups.length
              ? Array(modalItem.menuDealGroups.length).fill(null)
              : [],
        },
        modalOpen: true,
      },
      () => {
        setTimeout(() => {
          const splitPane = document.querySelector('ion-split-pane');
          if (splitPane) {
            splitPane.classList.add('blur');
          }
        }, 300);
      },
    );
  };

  handleInputChange = (groupIndex, choiceIndex, multiSelectionPermitted, event) => {
    const item = this.state.modalItem;
    const { selectedChoices, quantity } = this.state.modalItemOptions;
    const allGroups = item.menuDealGroups ? item.menuDealGroups : [];
    const selectedGroup = allGroups[groupIndex];
    if (selectedGroup) {
      const selectedChoice = selectedGroup.items[choiceIndex];
      let updatedSelectedChoices = deepCopy(selectedChoices);
      if (multiSelectionPermitted) {
        //checkbox
        if (event.target.checked) {
          updatedSelectedChoices[groupIndex].push(selectedChoice);
        } else {
          updatedSelectedChoices[groupIndex] = updatedSelectedChoices[groupIndex].filter(
            (i) => i.sku !== selectedChoice.sku,
          );
        }
      } else {
        //radio
        if (!selectedChoice) {
          updatedSelectedChoices[groupIndex] = [];
        } else {
          updatedSelectedChoices[groupIndex] = [selectedChoice];
        }
      }

      this.setState(
        {
          modalItemOptions: {
            ...this.state.modalItemOptions,
            selectedChoices: updatedSelectedChoices,
          },
        },
        () => {
          const { selectedChoices } = this.state.modalItemOptions;
          //recalculate item price on every menu deal choice change
          this.setState({ price: _calculateItemPrice({ item, quantity, selectedChoices }) }, () => {
            const validationErrors = validateItem(this.constructBasketItem());
            this.setState({ validationErrors: validationErrors.errors });
          });
        },
      );
    }
  };

  drawGroupChoices = (choices = [], multiSelectionPermitted, groupIndex) => {
    const { selectedChoices } = this.state.modalItemOptions;
    const { profile, __ } = this.props;
    const allChoices = choices.map((item, choiceIndex) => {
      const { sku, productPrice } = item;
      const isChecked = !!(selectedChoices[groupIndex] || []).find((i) => i.sku === sku);
      return (
        <IonItem lines="none" key={makeKey(choiceIndex, sku, groupIndex)}>
          <div tabIndex="-1"></div>
          {multiSelectionPermitted ? (
            <IonCheckbox
              color="primary"
              slot="start"
              checked={isChecked}
              onIonChange={(event) => {
                this.handleInputChange(groupIndex, choiceIndex, multiSelectionPermitted, event);
              }}
            />
          ) : (
            <IonRadio
              slot="start"
              className="details-radio"
              color="primary"
              value={choiceIndex}
              checked={isChecked}
            />
          )}
          <IonLabel className="ion-text-wrap">
            <Sectiontitle className="single-item">{__(getProductName(item, profile))}</Sectiontitle>
            {getProductDescription(item, profile) ? (
              <SmallText className="no-margin">{getProductDescription(item, profile)}</SmallText>
            ) : null}
          </IonLabel>
          <p>{formatPrice(productPrice)}</p>
        </IonItem>
      );
    });
    if (multiSelectionPermitted) {
      return allChoices;
    } else {
      //radio
      return (
        <IonRadioGroup
          onIonChange={(event) => {
            this.handleInputChange(groupIndex, event.target.value, multiSelectionPermitted, event);
          }}
        >
          {allChoices}
        </IonRadioGroup>
      );
    }
  };

  drawGroupLabel = (label, groupIndex) => {
    const { validationErrors } = this.state;
    const { __ } = this.props;

    return (
      <>
        <IonItem>
          <div className="sectiontitle">{label}</div>
        </IonItem>
        {validationErrors[groupIndex] ? (
          <div className="field-error">{__(validationErrors[groupIndex])}</div>
        ) : null}
      </>
    );
  };

  drawMenuDealGroups = (menuGroupItem, index) => {
    // multiSelectionPermitted = true  --> only one item must be selected
    const multiSelectionPermitted = menuGroupItem.multiSelectionPermitted;
    if (isChoicesGroupValid(menuGroupItem)) {
      if (isDefined(multiSelectionPermitted)) {
        return (
          <div key={index}>
            {this.drawGroupLabel(menuGroupItem.description, index)}
            {this.drawGroupChoices(menuGroupItem.items, multiSelectionPermitted, index)}
          </div>
        );
      } else {
        return this.drawGroupLabel(menuGroupItem.description);
      }
    }
  };

  constructBasketItem = () => {
    const { modalItem, modalItemOptions } = this.state;
    const { quantity, selectedChoices, instructions } = modalItemOptions;
    let constructModel = {};
    if (modalItem) {
      constructModel = {
        item: modalItem,
        quantity,
        selectedChoices,
        instructions,
      };
    }
    return constructModel;
  };

  onIncrementerUpdate = (newQuantity) => {
    const price = _calculateItemPrice({
      item: this.state.modalItem,
      quantity: newQuantity,
      selectedChoices: this.state.modalItemOptions.selectedChoices,
    });
    this.setState({
      modalItemOptions: { ...this.state.modalItemOptions, quantity: newQuantity, price },
    });
  };

  addToOrder = () => {
    const newBasketItem = this.constructBasketItem();
    const validationErrors = validateItem(newBasketItem);
    if (validationErrors.errorCount > 0) {
      this.setState({ validationErrors: validationErrors.errors });
      this.setState({ validationErrors: validationErrors.errors }, () => {
        this.setShowValidationAlert(true);
      });
    } else {
      addToBasket(newBasketItem);
      this.setState({
        modalItem: null,
        modalItemOptions: defaultModalItemOptions,
        modalOpen: false,
      });
      /*forwardTo('/order-summary')
			forwardTo('/order-summary', { fromItemDetails: true })*/
    }
  };

  instructionsChange = (event) => this.setState({ instructions: event.target.value });

  closeModal = () =>
    this.setState(
      { modalOpen: false, modalItemOptions: defaultModalItemOptions, validationErrors: [] },
      () => {
        const splitPane = document.querySelector('ion-split-pane');
        if (splitPane) {
          splitPane.classList.remove('blur');
        }
      },
    );
  isStoreOpened = () => {
    const { restaurants } = this.props;
    const allRestaurants = JSON.parse(JSON.stringify(restaurants));
    const filteredRestaurants = allRestaurants.filter(
      (el) => el.name !== 'Vouchers' && el.is_published,
    );
    const selectedRestaurant = filteredRestaurants[0];
    let currentDay = new Date().getDay();
    if (currentDay === 0) {
      currentDay = 7;
    }
    let days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

    let today = days[moment().format('d')];
    const openingTimes = selectedRestaurant.json_opening_time_info.filter(
      (el) => el.day === today,
    )[0];
    if (openingTimes && !Array.isArray(openingTimes.time)) {
      openingTimes.time = [openingTimes.time];
    }
    let label = 'CLOSED';
    if (openingTimes && openingTimes.time[0].toLowerCase() != 'closed') {
      const current = moment().format('HH-mm').split('-');
      const currentTime = { hour: current[0], minutes: current[1] };
      for (let i = 0; i < openingTimes.time.length; i++) {
        const openingTimesForToday = openingTimes.time[i].split('-').map((time) => {
          return {
            hour: time.split(':')[0].trim(),
            minutes: time.split(':')[1].trim(),
          };
        });
        const startTime = openingTimesForToday[0];
        const closeTime = openingTimesForToday[1];

        if (currentTime.hour < closeTime.hour && currentTime.hour > startTime.hour) {
          label = 'OPEN';
          break;
        } else if (currentTime.hour === closeTime.hour) {
          if (currentTime.minutes === closeTime.minutes) {
            label = 'OPEN';
            break;
          } else if (currentTime.minutes > closeTime.minutes) {
            label = 'OPEN';
            break;
          }
        } else if (currentTime.hour === startTime.hour) {
          if (currentTime.minutes === startTime.minutes) {
            label = 'OPEN';
            break;
          } else if (currentTime.minutes > startTime.minutes) {
            label = 'OPEN';
            break;
          }
        }
      }
    }

    return label;
  };
  getClass = (id) => {
    let backgroundImage;
    switch (id) {
      case 'delivery':
        backgroundImage = 'delivery-icon';
        break;
      case 'charter-delivery':
        backgroundImage = 'delivery-icon';
        break;
      case 'table':
        backgroundImage = 'table-icon';
        break;
      default:
        backgroundImage = 'collection-icon';
    }
    return backgroundImage;
  };
  onContentScrollHandler() {
    const nameVisible = this.isScrolledIntoView(document.querySelector('.item-details-modal-name'));
    if (this.state.nameVisible !== nameVisible) {
      if (!nameVisible) {
        this.setState({ nameVisible: false });
      } else {
        this.setState({ nameVisible: true });
      }
    }
  }
  isScrolledIntoView(el) {
    var rect = el.getBoundingClientRect();
    var elemTop = rect.top - 50;
    var elemBottom = rect.bottom;
    var isVisible = elemTop >= 0 && elemBottom <= window.innerHeight;
    return isVisible;
  }

  filterMenu(menu) {
    if (menu && menu.menuEntryGroups) {
      menu.menuEntryGroups = this.filterIkentooMenuItems(menu.menuEntryGroups);
      return menu;
    }
    return menu;
  }
  filterIkentooMenuItems(items) {
    let filteredItems = [];
    items.forEach((item) => {
      if (!item.menuEntry) {
        // break recursion when arrive to the product
        if (
          getProductName(item, this.props.profile)
            .toLowerCase()
            .includes(`${this.state.searchItemsValue.toLowerCase()}`)
        ) {
          filteredItems.push(item);
          return [item];
        } else {
          return [];
        }
      } else {
        const len = (item.menuEntry || []).length;
        if (len > 0) {
          const newFilteredItems = this.filterIkentooMenuItems(item.menuEntry);
          if (newFilteredItems.length > 0) {
            item.menuEntry = newFilteredItems;
            filteredItems.push(item);
          }
        }
      }
    });

    return filteredItems;
  }
  filterMenuItems = (value) => {
    this.setState({ searchItemsValue: value }, () => {
      const menu = JSON.parse(JSON.stringify(this.state.originalMenu));
      const filtered = this.filterMenu(menu);
      this.setState({ ikentooMenu: filtered }, () => {
        let menuRefs = this.menuRefs(this.state.ikentooMenu);
        this.setState({ menuRefs });
      });
    });
  };
  render() {
    const { __, profile, history, restaurants } = this.props;
    const {
      selectedCategory,
      ikentooMenu,
      modalOpen,
      modalItem,
      menuHero,
      description,
    } = this.state;
    let categories = !isEmptyObject(ikentooMenu) ? ikentooMenu.menuEntryGroups : [];
    let modalItemImage =
      modalItem && modalItem.itemRichData && modalItem.itemRichData.squareImageUrl
        ? modalItem.itemRichData.squareImageUrl
        : '';
    const isLogoClicked = history?.location?.state?.isLogoClicked;
    if (modalItemImage && modalItemImage.indexOf('http://') !== -1) {
      modalItemImage = modalItemImage.replace(/http:\/\//g, 'https://');
    }
    const filteredRestaurants = restaurants.filter(
      (restaurant) => restaurant.name !== 'Vouchers' && restaurant.is_published,
    );
    return (
      <Loading>
        <Layout
          noPadding
          scrollY={false}
          // color='secondary'
          selectCategoryOnScroll={this.selectCategoryOnScroll}
        >
          {!isDefined(ikentooMenu) && !isEmptyObject(ikentooMenu) && !ikentooMenu ? null : (
            <div
              className={
                'order-list' +
                (Basket.items.length < 1 || Basket.getDeliveryOption()?.id === 'gift-vouchers'
                  ? ' order-list-full'
                  : '')
              }
            >
              {ikentooMenu &&
              ikentooMenu.menuName &&
              ikentooMenu.menuName.indexOf('Default') === -1 &&
              !isLogoClicked ? null : (
                <div className="web-only order-list-heading">
                  <div className="order-list-restaurant-image">
                    <div className="order-list-restaurant-bg"></div>
                  </div>
                  <div className="restaurant-info-banner">
                    <img alt="client logo" className="client-logo" src={logo} />
                    <div style={{ flex: 1 }}>
                      <NormalText className="block">
                        {description?.replace(/(<([^>]+)>)/gi, '')}
                      </NormalText>
                    </div>
                    {filteredRestaurants.length > 0 && (
                      <div className="restaurant-info-delivery-options">
                        <div>
                          <StrongText className="block">{this.isStoreOpened()}</StrongText>
                          <NormalText>{filteredRestaurants[0].name}</NormalText>
                        </div>
                        <div className="icons-wrapper">
                          {getConfig().delivery.map((d, index) =>
                            d.isRemoved ? null : (
                              <div
                                key={index}
                                className={this.getClass(d.id)}
                                onClick={() => this.setDeliveryOption(d)}
                              ></div>
                            ),
                          )}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              )}
              <div className="order-categories">
                <div className="search-box-wrapper">
                  <Title style={{ margin: 0 }}>{__('Select items')}</Title>
                  <div className="search-box">
                    <IonIcon color="secondary" icon={search}></IonIcon>
                    <IonInput
                      placeholder={__('Search products')}
                      value={this.state.searchItemsValue}
                      onIonChange={(e) => this.filterMenuItems(e.target.value)}
                    />
                    <IonIcon
                      onClick={() => this.setState({ searchItemsValue: '' })}
                      className="reset-icon"
                      color="secondary"
                      icon={close}
                    ></IonIcon>
                  </div>
                </div>
                {categories.length > 0 ? (
                  <div className="order-categories-inner">
                    {categories.map((category, index) => {
                      return (
                        <IonButton
                          key={index}
                          size="small"
                          fill="clear"
                          className={
                            selectedCategory === index
                              ? 'category-button active'
                              : 'category-button'
                          }
                          onClick={() => this.scrollTo(`${category.name}_${index}`, index)}
                        >
                          <StrongText>{__(category?.name)}</StrongText>
                        </IonButton>
                      );
                    })}
                  </div>
                ) : (
                  <NormalText className="">{__('No items found')}</NormalText>
                )}
              </div>
              <div className="order-content">
                <OrderList
                  categoryHeight={this.state.categoryHeight}
                  showModal={this.showModal}
                  category={!isEmptyObject(ikentooMenu) ? ikentooMenu : null}
                  menuRefs={this.state.menuRefs ? this.state.menuRefs : []}
                />
              </div>
            </div>
          )}
          {Basket.getDeliveryOption().id !== 'gift-vouchers' && (
            <>
              {Basket.items.length > 0 ? (
                <div className="order-summary-sidebar">
                  <OrderSummary />
                </div>
              ) : null}
            </>
          )}
        </Layout>
        {modalOpen && (
          <Modal
            className="item-details-modal"
            isOpen={modalOpen}
            onDidDismiss={() => this.closeModal()}
          >
            {modalItem ? (
              <>
                <div className="item-details-card">
                  <div
                    onScroll={() => this.onContentScrollHandler()}
                    className={'item-details-content'}
                    style={
                      getConfig().delivery.filter((d) => !d.isRemoved && !d.isDisabled).length === 0
                        ? { height: '100%' }
                        : {}
                    }
                  >
                    <div
                      className={
                        this.state.nameVisible
                          ? 'item-details-model-header'
                          : 'item-details-model-header active'
                      }
                    >
                      <Title>{__(getProductName(modalItem, this.props.profile))}</Title>
                    </div>
                    <div className="item-details-modal-info">
                      {modalItemImage && modalItemImage !== '' ? (
                        <img alt="modal item" src={modalItemImage} />
                      ) : (
                        <div></div>
                      )}
                      <div
                        className={`item-details-name ${
                          modalItemImage && modalItemImage !== '' ? '' : 'item-details-name-no-img'
                        }`}
                      >
                        <Title className="okx-font-secondary item-details-modal-name">
                          {__(getProductName(modalItem, this.props.profile))}
                        </Title>
                        <div className="item-details-card-price okx-font-secondary">
                          {formatPrice(modalItem.productPrice)}
                        </div>
                      </div>
                    </div>
                    <ItemDetailsRaw
                      item={modalItem}
                      profile={profile}
                      modalOpen={modalOpen}
                      closeModal={this.closeModal}
                    />
                  </div>
                </div>
              </>
            ) : null}
          </Modal>
        )}
        {/* <IonAlert
					isOpen={ showValidationAlert }
					onDidDismiss={() => this.setState({ showValidationAlert: false })}
					header={ __('Validation') }
					message={ __('Please check any required options') }
					buttons={[{ text: 'Cancel', role: 'cancel', cssClass: 'secondary' }]}
				/> */}
      </Loading>
    );
  }
}

const stateToProps = (state) => {
  const { profile, auth } = state.profile;
  const { restaurants, ikentooMenu, defaultMenu, restaurantsUpdated } = state.restaurants;

  const { scrollTop } = state.orders;
  return {
    auth,
    restaurants: restaurants || [],
    ikentooMenu: ikentooMenu || {},
    defaultMenu: defaultMenu || {},
    basketUpdated: state.orders.basketUpdated,
    scrollTop,
    storedItemWeb: state.orders.storedItemWeb,
    restaurantsUpdated,
    profile,
  };
};

export default connect(stateToProps)(withTranslation(OrderPage));
