import { isWebConfig } from './lib/utils';
import Dashboard from './screens/dashboard';
import Loyalty from './screens/loyalty';
import Vouchers from './screens/vouchers';
import Account from './screens/account';
import ReferAFriend from './screens/referAFriend';
import Feedback from './screens/feedback';
import History from './screens/history';
import HistoryDetails from './screens/historyDetails';
import Locations from './screens/locations';
import Social from './screens/social';
import Login from './screens/login';
import Register from './screens/register';
import ResetPassword from './screens/resetPassword';
import Terms from './screens/terms';
import Privacy from './screens/privacy';
import Faq from './screens/faq';
// import Order from './screens/order'
import ItemDetails from './screens/itemDetails';
import DeliveryOptions from './screens/delivery-options';
import Delivery from './screens/delivery';
import DeliveryAddressCheck from './screens/delivery-address-check';
import DeliveryAddressAdd from './screens/delivery-address-add';
import DeliveryTime from './screens/delivery-time';
import PickUpPoint from './screens/pick-up-point';
import PickUpPointCheck from './screens/pick-up-point-check';
import ClickAndCollect from './screens/clickAndCollect';
import Cards from './screens/cards';
import CardAdd from './screens/cardAdd';
import OrderSummary from './screens/orderSummary';
import ApplyPoints from './screens/applyPoints';
import Checkout from './screens/checkout';
import OrderCompleted from './screens/orderCompleted';
import ContactDetails from './screens/contactDetails';
import OrderToTable from './screens/orderToTable';
import ApplyVouchers from './screens/applyVouchers';
import { getConfig } from './appConfig';
import { getSingleDeliveryOption } from './lib/utils';
import OurBrands from './screens/ourBrands';
import ApplyLoyalty from './screens/applyLoyalty';

const Order = require('./screens/order' + (isWebConfig() ? 'Web' : '')).default;

const home = require('./assets/images/home-icon.svg');
const loyalty = require('./assets/images/icons/loyalty.svg');
const myAccount = require('./assets/images/icons/account.svg');
const feedback = require('./assets/images/icons/feedback.svg');
const history = require('./assets/images/icons/history.svg');
const location = require('./assets/images/icons/location.svg');
const social = require('./assets/images/icons/social.svg');
const login = require('./assets/images/login-icon.svg');
const logout = require('./assets/images/logout-icon.svg');
const referAFriend = require('./assets/images/icons/refer.svg');
const faq = require('./assets/images/icons/faq.svg');
const menu = require('./assets/images/menu1.svg');

const { appType } = getConfig();
const { hasCampaignManager, hasOrdering, hasLoyalty } = appType;

const singleDeliveryOption = getSingleDeliveryOption();

const navConfig = {
  routes: [
    isWebConfig()
      ? []
      : {
          label: 'Home',
          path: '/dashboard',
          component: Dashboard,
          icon: home,
          exact: true,
          default: true,
        },
    { label: 'Menu', path: '/order', component: Order, icon: menu },
    ...(hasOrdering
      ? [
          {
            label: 'Start new order',
            path: '/delivery-options',
            component: DeliveryOptions,
            exact: true,
            default: true,
            protected: false,
            notInDrawer: !!singleDeliveryOption,
          },
        ]
      : []),
    ...(hasOrdering
      ? [
          {
            label: singleDeliveryOption ? singleDeliveryOption.label : '',
            path: '/click-and-collect',
            component: ClickAndCollect,
            protected: false,
            notInDrawer: !singleDeliveryOption,
          },
        ]
      : []),
    ...(hasLoyalty
      ? [
          {
            label: 'Loyalty wallet',
            path: '/loyalty',
            component: Loyalty,
            icon: loyalty,
            protected: true,
            state: { tab: 'points' },
            group: 1,
          },
        ]
      : []),
    ...(hasCampaignManager
      ? [
          {
            label: 'Vouchers',
            path: '/vouchers',
            component: Vouchers,
            protected: true,
          },
        ]
      : []),
    ...(hasCampaignManager
      ? [
          {
            label: 'Refer a friend',
            path: '/refer-a-friend',
            component: ReferAFriend,
            icon: referAFriend,
            protected: true,
            group: 1,
          },
        ]
      : []),
    {
      label: 'My account',
      path: '/account',
      component: Account,
      icon: myAccount,
      protected: true,
      group: 2,
    },
    ...(hasOrdering
      ? [
          {
            label: 'Checkout',
            path: '/checkout',
            component: Checkout,
            notInDrawer: true,
            protected: true,
          },
        ]
      : []),
    ...(hasOrdering
      ? [
          {
            label: 'Apply pPoints',
            path: '/apply-points',
            component: ApplyPoints,
            notInDrawer: true,
            protected: true,
          },
        ]
      : []),
    {
      label: 'History',
      path: '/history',
      component: History,
      icon: history,
      protected: true,
      group: 2,
    },
    {
      label: 'Leave feedback',
      path: '/feedback',
      component: Feedback,
      icon: feedback,
      protected: true,
      group: 2,
    },
    {
      label: 'Locations',
      path: '/locations',
      component: Locations,
      icon: location,
      protected: false,
      group: 3,
    },
    {
      label: 'Social media',
      path: '/social',
      component: Social,
      icon: social,
      protected: false,
      group: 3,
    },
    {
      label: 'Contact details',
      path: '/contact-details',
      component: ContactDetails,
      protected: true,
      notInDrawer: true,
    },
    {
      label: 'Apply vouchers',
      path: '/apply-vouchers',
      component: ApplyVouchers,
      notInDrawer: true,
      protected: true,
    },
    {
      label: 'Apply loyalty',
      path: '/apply-loyalty',
      component: ApplyLoyalty,
      icon: menu,
      protected: true,
      notInDrawer: true,
    },
    { label: 'FAQs', path: '/faq', component: Faq, icon: faq, group: 3 },
  ],
  authRoutes: [
    { label: 'Login', path: '/login', component: Login, icon: logout, fn: 'login' },
    { label: 'Logout', path: '/logout', icon: login, fn: 'logout' },
    { path: '/contact-details', component: ContactDetails },
  ],
  additionalRoutes: [
    { label: 'Terms & Conditions', path: '/terms', component: Terms },
    { label: 'Privacy Policy', path: '/privacy', component: Privacy },
  ],
  notInMenuRoutes: [
    { path: '/register', component: Register },
    { path: '/reset-password', component: ResetPassword },
    ...(hasOrdering ? [{ path: '/item-details', component: ItemDetails }] : []),
    ...(hasOrdering ? [{ path: '/order', component: Order }] : []),
    ...(hasOrdering ? [{ path: '/cards', component: Cards }] : []),
    ...(hasOrdering ? [{ path: '/card-add', component: CardAdd }] : []),
    ...(hasOrdering ? [{ path: '/history-details', component: HistoryDetails }] : []),
    ...(hasOrdering ? [{ path: '/order-summary', component: OrderSummary }] : []),
    ...(hasOrdering ? [{ path: '/order-completed', component: OrderCompleted }] : []),
    ...(hasOrdering ? [{ path: '/delivery', component: Delivery }] : []),
    ...(hasOrdering ? [{ path: '/delivery-address-check', component: DeliveryAddressCheck }] : []),
    ...(hasOrdering ? [{ path: '/delivery-address-add', component: DeliveryAddressAdd }] : []),
    ...(hasOrdering ? [{ path: '/delivery-time', component: DeliveryTime }] : []),
    ...(hasOrdering ? [{ path: '/pick-up-point', component: PickUpPoint }] : []),
    ...(hasOrdering ? [{ path: '/pick-up-point-check', component: PickUpPointCheck }] : []),
    ...(hasOrdering ? [{ path: '/order-to-table', component: OrderToTable }] : []),
  ],
};

export default navConfig;
