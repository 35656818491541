import React from 'react';
import { IonButton, /*, isPlatform */ IonIcon } from '@ionic/react';
import { connect } from 'react-redux';
import Loading from '../../components/spinner';
import Layout from '../../components/layout';
import Modal from '../../components/modal';
import { Title, Spacer, NormalText, SmallText, Subtitle } from '../../components/common';
import { isDefined, formatNumber, formatPoints } from '../../lib/utils';
import { withTranslation } from '../../lib/translate';
import { forwardTo } from '../../lib/utils';
import QRCode from 'qrcode-react';
import moment from '../../lib/moment';
import { lockClosed } from 'ionicons/icons';
import './index.css';
// import Basket from '../../lib/basket'
import Basket from '../../lib/basket';
import { VoucherItem } from '../../screens/loyalty';
// import voucherDummyImg from '../../assets/images/refer-a-friend.jpg'

const RewardItem = ({ reward, id, className, __, action, available_balance }) => {
  const isUnlocked = available_balance >= reward.cost;
  return (
    <div
      onClick={() => (isUnlocked ? action(id) : null)}
      className="reward"
      style={{ backgroundImage: `url(${reward.image})` }}
    >
      {!isUnlocked && (
        <div class="reward-locked">
          <IonIcon icon={lockClosed} color="white" />
        </div>
      )}
      <Subtitle>{reward.name}</Subtitle>
      <NormalText>
        {reward.cost} {__('Points')}
      </NormalText>
    </div>
  );
};

class UnlockRewardItmes extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedReward: null,
    };
  }

  viewReward = (id) => {
    const { rewards } = this.props;
    this.setState({ selectedReward: rewards.find((r) => r.id === id) });
  };

  render() {
    const { __, qr_code, available_balance, rewards } = this.props;
    const { selectedReward } = this.state;
    return (
      <>
        <div className="rewards-wrapper">
          {(rewards || []).map((reward, index) => {
            return (
              <RewardItem
                key={'rw-' + index}
                {...reward}
                __={__}
                available_balance={available_balance}
                action={() => this.viewReward(reward.id)}
              />
            );
          })}
        </div>

        <Modal
          className="voucher-modal"
          isOpen={selectedReward !== null}
          onDidDismiss={() => this.setState({ selectedReward: null })}
        >
          <Title>{__('Voucher Details')}</Title>
          <VoucherItem {...selectedReward} __={__} />
          {selectedReward?.reward || selectedReward?.reward?.reward ? (
            <>
              <Spacer size={1} />
              <>
                {isDefined(qr_code) && qr_code !== '' ? (
                  <div className="qr-holder">
                    <QRCode value={qr_code} size={150} />
                  </div>
                ) : (
                  <div className="noQrCode">
                    <h5>{__('NO QR CODE')}</h5>
                  </div>
                )}
              </>
              <Spacer size={1} />
              <div className="voucher-modal-content">
                <NormalText className="block">{__('Voucher info')}</NormalText>
                <Spacer size={1} />
                {selectedReward.reward.description ? (
                  <div>
                    <NormalText>{selectedReward.reward.description}</NormalText>
                  </div>
                ) : null}
              </div>
            </>
          ) : null}
        </Modal>
      </>
    );
  }
}

const stateToProps = (state) => {
  return {
    vouchers: state.profile.vouchers || [],
    // qr_code: state.profile.profile.qr_code,
  };
};

export default connect(stateToProps)(withTranslation(UnlockRewardItmes));
