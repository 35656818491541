import React, { Component } from 'react';
import { connect } from 'react-redux';
import { IonIcon, IonGrid, IonCol, IonRow, IonRippleEffect } from '@ionic/react';
import { logoTwitter, logoFacebook, logoLinkedin, logoInstagram, globe } from 'ionicons/icons';
import { getSocials } from '../../store/actions';
import Layout from '../../components/layout';
import Loading from '../../components/spinner';
import { Title, SmallText, Spacer } from '../../components/common';
import { ucWords, isDefined, isEmptyObject, forwardTo } from '../../lib/utils';
import { withTranslation } from '../../lib/translate';
import './index.css';
import NoData from '../../components/noData';

const theHarboutInn = require('../../assets/images/the-harbour-inn.jpg');
const pullman = require('../../assets/images/pullman.png');
const tinAndTap = require('../../assets/images/tin&tap.jpg');
const luben = require('../../assets/images/luben.jpg');
const folkstone = require('../../assets/images/folkstone.jpg');
const marketSquare = require('../../assets/images/market-square.jpg');
const feast = require('../../assets/images/feast.png');

const openExternalLink = (url) => window.open(url, '_system', 'location=yes');
const brands = [
  {
    id: 2,
    name: 'The Harbour Inn',
    link: 'https://www.theharbourinnfolkestone.co.uk',
    image: `${theHarboutInn}`,
  },
  { id: 1, name: 'The Pullman', link: 'https://www.thepullmanfolkestone.com', image: `${pullman}` },
  { id: 3, name: 'Tin&Tap', link: 'https://www.tinandtap.co.uk', image: `${tinAndTap}` },
  { id: 4, name: 'Luben', link: 'https://www.luben.co.uk', image: `${luben}` },
  { id: 5, name: 'Folkstone', link: 'https://www.landb-restaurants.co.uk', image: `${folkstone}` },
  {
    id: 6,
    name: 'Market Square',
    link: 'https://www.marketsquarefolkestone.co.uk',
    image: `${marketSquare}`,
  },
  { id: 7, name: 'Feast', link: 'https://www.eat-feast.co.uk', image: `${feast}` },
];

class OurBrands extends Component {
  handleRedirect = (link) => {
    if (link) {
      openExternalLink(link);
    }
  };

  render() {
    const { __ } = this.props;
    return (
      <Loading>
        <Layout color="white" headerTitle={__('Social Media')}>
          <div className="header-wrapper">
            <Title>{__('Our Brands')}</Title>
            <SmallText color="primary" className="heading thiner-text">
              {__('Click on logo to visit the brands homepage')}
            </SmallText>
          </div>
          <Spacer size={2} />
          <div className="frm-wrapper">
            {isDefined(brands) ? (
              <IonGrid className="no-padding brands">
                <IonRow>
                  {(brands || []).map((item) => {
                    const key = item.id;
                    const link = item.link;
                    const image = item.image;
                    const name = item.name;
                    return (
                      <div
                        key={key}
                        className="brand ion-activatable"
                        onClick={() => this.handleRedirect(link)}
                      >
                        <img src={image} alt={name} draggable={false} />
                        <IonRippleEffect></IonRippleEffect>
                      </div>
                    );
                  })}
                </IonRow>
              </IonGrid>
            ) : (
              <NoData label={__('No brands')} />
            )}
          </div>
        </Layout>
      </Loading>
    );
  }
}

export default connect(null)(withTranslation(OurBrands));
