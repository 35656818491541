import React from 'react';
import { connect } from 'react-redux';
import { IonButton, IonItem, IonLabel, IonList, IonRadioGroup, IonRadio } from '@ionic/react';
import Layout from '../../components/layout';
import { Title, SmallText, Spacer, NormalText } from '../../components/common';
import { withTranslation } from '../../lib/translate';
import moment from '../../lib/moment';
import {
  forwardToDeliveryOption,
  isEmptyObject,
  isObject,
  isArray,
  cutoffTime,
  isDefined,
  groupBy,
} from '../../lib/utils';
import { setDeliveryTime, setDeliveryOption } from '../../store/actions';
import { setCommonModal } from '../../store/common/actions';
import { getIkentooMenu, getIkentooMenusForLocation } from '../../store/restaurants/actions';
import Mobiscroll from '../../components/mobiscroll';
import Basket from '../../lib/basket';
import Loading from '../../components/spinner';
import NoData from '../../components/noData';
import { formatDataForTime, getMenusForSelectedTime } from '../../screens/clickAndCollect';
import { getConfig } from '../../appConfig';
import mobiscroll from '@mobiscroll/react';

import './index.css';

const { SelectOption } = Mobiscroll;
//const customTime = '2020-06-09T05:59:00.000'

class DeliveryTime extends React.Component {
  state = {
    selectedTime: null,
    pickTime: null,
    formatted: null,
  };
  checkDelivery = () => {
    if (!Basket.getDeliveryOption() || (!Basket.getDeliveryAddress() && !Basket.getPickUpPoint())) {
      forwardToDeliveryOption();
    }
  };

  componentDidMount() {
    this.checkDelivery();
    this.props.dispatch(
      getIkentooMenusForLocation(
        Basket.getRestaurant() ? Basket.getRestaurant().business_location_id : null,
        {},
        true,
      ),
    );
  }

  componentDidUpdate(prevProps) {
    if (this.props.deliveryOption !== prevProps.deliveryOption) {
      this.checkDelivery();
    }
    if (this.props.ikentooMenusForLocation.length !== prevProps.ikentooMenusForLocation.length) {
      if (this.props.ikentooMenusForLocation[0]) {
        this.setState(
          { selectedIkentooMenu: this.props.ikentooMenusForLocation[0].ikentooMenuId },
          () => Basket.setMenu(this.props.ikentooMenusForLocation[0].ikentooMenuId),
        );
      }
    }
  }
  confirmTime = () => {
    const { selectedTime } = this.state;
    if (selectedTime) {
      this.props.dispatch(setDeliveryTime(selectedTime));
      Basket.setCollectionTime(selectedTime.formattedDT);
      let restaurant = Basket.getRestaurant();
      let slots = restaurant?.delivery_times_json?.slots;
      let cutoffTimeRes = cutoffTime(selectedTime.formattedDT, slots);
      Basket.setCutoffTime(cutoffTimeRes);
      this.continueOnMenu();
    }
  };

  changeIkentooMenus = (event) =>
    this.setState({ selectedIkentooMenu: event.detail.value, error: '' }, () =>
      Basket.setMenu(event.detail.value),
    );

  formatDayName = (name, key) => {
    if (name.includes('Today')) {
      name = 'Today';
    } else if (name.includes('Tomorrow')) {
      name = 'Tomorrow';
    } else {
      name = key;
    }
    return name;
  };

  formatTimes = (deliveryTimesJson) => {
    const { deliveryOption } = this.props;
    const formatSelectOptionDays = [];
    const daysAhead = deliveryTimesJson ? deliveryTimesJson.days_ahead : null;
    let picker = [];
    if (deliveryTimesJson) {
      Array(daysAhead)
        .fill('')
        .forEach((day, i) => {
          let formatDay = moment().add(i, 'days');
          let formatDayName = formatDay.format('dddd');
          formatSelectOptionDays.push({ formatDayName, formattedDT: formatDay });
        });
      formatSelectOptionDays.forEach((option, i) => {
        let daySlot = deliveryTimesJson.slots[option.formatDayName];
        daySlot.forEach((slot) => {
          let h = parseInt(slot.start_time.split(':')[0]);
          let m = parseInt(slot.start_time.split(':')[1]);
          let prepTime = moment().add(slot.prep_time, 'hours');
          const formattedDT = moment(option.formattedDT.hours(h).minutes(m));
          if (prepTime.isBefore(formattedDT)) {
            picker.push({
              formattedDT: formattedDT,
              value: formattedDT.toDate(),
              date: formattedDT.format('ddd, DD MMM'),
              time: formattedDT.format('h:mm A'),
              menuIds: slot.menu_ids || null,
            });
          }
        });
      });
      const list = this.formatTimesForTree(picker);

      return { list };
    }
    return { list: [] };
  };
  formatTimesForTree = (times) => {
    const groupByMonth = groupBy(['date']);
    const grouped = groupByMonth(times);
    return grouped;
  };
  continueOnMenu = () => {
    const { dispatch, deliveryOption } = this.props;
    const { selectedIkentooMenu, selectedTime } = this.state;
    const isCharterDelivery = deliveryOption
      ? deliveryOption.id === 'charter-delivery'
        ? true
        : false
      : false;
    if (Basket.getRestaurant() && selectedIkentooMenu) {
      const choosenRestaurant = Basket.getRestaurant();
      const businessLocationId = choosenRestaurant.business_location_id;
      Basket.setCollectionTime(isCharterDelivery ? this.state.formatted : selectedTime.formattedDT);
      dispatch(getIkentooMenu(selectedIkentooMenu, businessLocationId));
      Basket.setPassedOrder(true);
    } else {
      this.setState({ error: 'Please select location menu' });
    }
  };
  getMenusForDeliverySelectedTime = (menusForLocation = [], selectedTime) => {
    if (isObject(menusForLocation) && !isArray(menusForLocation)) {
      menusForLocation = [menusForLocation];
    }
    return menusForLocation.filter((menu) => {
      const ikentooMenuId = menu.ikentooMenuId;
      if (!isEmptyObject(selectedTime)) {
        if (selectedTime !== 'asap') {
          const target_menu = selectedTime.menuIds.find((i) => i === ikentooMenuId);
          if (target_menu) {
            return true;
          }
        } else {
          return true;
        }
      }
      return false;
    });
  };
  init(findPickedDT) {
    this.setState({ selectedIkentooMenu: findPickedDT.menuIds[0] });
  }
  changeTime = (selectedTime, minDT) => {
    if (selectedTime && minDT) {
      let h = parseInt(selectedTime.split(':')[0]);
      let m = parseInt(selectedTime.split(':')[1]);
      const formattedDT = moment(minDT).hours(h).minutes(m);
      this.setState({ pickTime: selectedTime, selectedTime, formatted: formattedDT }, () => {
        Basket.setCollectionTime(formattedDT);
        Basket.setOrderType('charter-delivery');
        this.confirmTime();
      });
    } else {
      this.setState({ pickTime: null });
    }
  };

  setPickTime = (pickTime, inst, minDT) => {
    if (inst.getVal()) {
      this.changeTime(inst.getVal(), minDT);
    } else {
      this.setState({ pickTime: null });
    }
  };
  parseSelectedTime(dataSource, selected) {
    const [selectedDateIndex, selectedTimeIndex] = selected.split(' ');
    const selectedDate = Object.keys(dataSource)[selectedDateIndex];
    const selectedTime = dataSource[selectedDate][selectedTimeIndex];
    let restaurant = Basket.getRestaurant();
    let slots = restaurant?.delivery_times_json?.slots;
    let cutoffTimeRes = cutoffTime(selectedTime.formattedDT, slots);
    this.setState({ selectedTime: selectedTime }, () => {
      if (selectedTime.menuIds.length > 1) {
        this.props.dispatch(setCommonModal('isChooseMenuModalOpen', true));
      } else if (selectedTime.menuIds.length === 1) {
        this.setState({ selectedIkentooMenu: selectedTime.menuIds[0] });
      }
    });
    Basket.setCutoffTime(cutoffTimeRes);
  }

  render() {
    const { __, deliveryOption, isChooseMenuModalOpen, ikentooMenusForLocation } = this.props;
    const deliveryOptionBasket = deliveryOption || Basket.getDeliveryOption();
    const { selectedTime, selectedIkentooMenu, pickTime } = this.state;
    const restaurant = Basket.getRestaurant();
    const charterDelivery = deliveryOptionBasket
      ? deliveryOptionBasket.id === 'charter-delivery'
        ? true
        : false
      : false;
    const deliveryTimesJson = restaurant ? restaurant.delivery_times_json : null;
    const charterTimesJson = restaurant ? restaurant.charter_delivery_times_json : null;
    const timesJson = deliveryOptionBasket
      ? deliveryOptionBasket.id === 'charter-delivery'
        ? charterTimesJson
        : deliveryTimesJson
      : deliveryTimesJson;
    const hasTimeDelivery =
      !isEmptyObject(deliveryTimesJson) && !isEmptyObject(deliveryTimesJson.slots);
    const hasTimeCharterDelivery = !isEmptyObject(charterTimesJson);
    const hasTime = hasTimeDelivery ? hasTimeDelivery : hasTimeCharterDelivery;
    const animationMenuClass = isChooseMenuModalOpen ? 'show-up' : '';
    const menus = charterDelivery
      ? getMenusForSelectedTime(ikentooMenusForLocation, selectedTime, charterTimesJson)
      : this.getMenusForDeliverySelectedTime(ikentooMenusForLocation, selectedTime);
    const currentDT = moment();
    let minDT = currentDT;
    if (restaurant && isDefined(restaurant.charter_delivery_order_slot_lead_time)) {
      minDT.add(restaurant.charter_delivery_order_slot_lead_time, 'minutes');
    }
    const timePickerOptions = formatDataForTime(restaurant, minDT, restaurant.id, true, false, []);
    const can_delivery_order =
      restaurant && isDefined(restaurant.can_delivery_order)
        ? restaurant.can_delivery_order
        : false;
    const timePickerTimes = this.formatTimes(timesJson).list;
    return (
      <Layout headerWithTitle headerTitle={__('Delivery Time')}>
        <div className="flex-row-wrapper absolute-content">
          <div className="scrollable-y">
            <Title>{__('Delivery Time')}</Title>
            {hasTime ? (
              <>
                <NormalText>
                  {__(
                    deliveryOption &&
                      (deliveryOption.id === 'delivery' || deliveryOption.id === 'charter-delivery')
                      ? 'Select the time for your order to be delivered'
                      : 'Select your prefered drop-off time',
                  )}
                </NormalText>
                <Spacer />

                <mobiscroll.Treelist
                  display="inline"
                  layout="liquid"
                  showInput={false}
                  circular={false}
                  onChange={(e) => this.parseSelectedTime(timePickerTimes, e.valueText)}
                  onInit={(e) => {
                    this.parseSelectedTime(timePickerTimes, '0 0');
                  }}
                >
                  {Object.keys(timePickerTimes).map((date, i) => (
                    <li key={i}>
                      {date}
                      <ul>
                        {timePickerTimes[date].map((time, j) => (
                          <li key={j}>{time.time}</li>
                        ))}
                      </ul>
                    </li>
                  ))}
                </mobiscroll.Treelist>
              </>
            ) : null}
          </div>
          <div className="flex-min">
            <IonButton
              disabled={
                can_delivery_order
                  ? charterDelivery
                    ? !pickTime && !selectedTime
                    : !selectedTime
                  : true
              }
              expand="block"
              color="primary"
              onClick={() => this.confirmTime()}
            >
              {__('Continue')}
            </IonButton>
          </div>
        </div>
        <div
          className="click-collect-pickers-backdrop"
          style={{ display: isChooseMenuModalOpen ? '' : 'none' }}
          onClick={() => this.props.dispatch(setCommonModal('isChooseMenuModalOpen', false))}
        ></div>
        <div className={`click-collect-dialog ${animationMenuClass}`}>
          <Loading transparent> {null} </Loading>
          <div className="click-collect-dialog-layout sc-ion-modal-md">
            <div className="click-collect-dialog-header">
              <h3>{__('Choose menu')}</h3>
            </div>
            <div
              className="click-collect-dialog-closer"
              style={{ position: 'absolute', right: 0, top: 0 }}
              onClick={() => this.props.dispatch(setCommonModal('isChooseMenuModalOpen', false))}
            >
              <ion-icon name="close" role="img" class="md hydrated" aria-label="close"></ion-icon>
            </div>
            <div className="click-collect-dialog-content">
              <IonList lines="full">
                <IonRadioGroup onIonChange={this.changeIkentooMenus} value={selectedIkentooMenu}>
                  {menus.length === 0 ? (
                    <NoData />
                  ) : (
                    menus.map((menu) => {
                      const { ikentooMenuId, menuName } = menu;
                      return (
                        <IonItem key={ikentooMenuId}>
                          <div tabIndex="-1"></div>
                          <IonRadio color="tertiary" slot="start" value={ikentooMenuId} />
                          <IonLabel className="ion-text-wrap" color="dark">
                            {menuName}
                          </IonLabel>
                        </IonItem>
                      );
                    })
                  )}
                </IonRadioGroup>
              </IonList>
            </div>
            <div className="click-collect-dialog-action">
              <IonButton
                disabled={menus.length > 0 ? false : true}
                expand="block"
                color="primary"
                onClick={() => this.continueOnMenu()}
              >
                {__('Next')}
              </IonButton>
            </div>
          </div>
        </div>
      </Layout>
    );
  }
}

const stateToProps = (state) => {
  const { deliveryOption, deliveryAddress, pickUpPoint } = state.orders;
  const { isChooseMenuModalOpen } = state.common;
  const { ikentooMenusForLocation, restaurants } = state.restaurants;
  return {
    deliveryOption,
    deliveryAddress,
    pickUpPoint,
    isChooseMenuModalOpen,
    ikentooMenusForLocation,
    restaurants,
  };
};

export default connect(stateToProps)(withTranslation(DeliveryTime));
