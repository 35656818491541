import React from 'react';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { Plugins, Capacitor } from '@capacitor/core';
import {
  IonMenu,
  IonHeader,
  IonToolbar,
  IonContent,
  IonList,
  IonItem,
  IonMenuToggle,
  IonButtons,
  IonButton,
  IonIcon,
  IonLabel,
  IonFooter,
  IonAlert,
  IonItemGroup,
  IonAvatar,
} from '@ionic/react';
import navConfig from '../../navConfig.js';
import { logout, setDeliveryOption } from '../../store/actions';
import { getDefaultRoute, forwardTo, padNumber, isWebConfig } from '../../lib/utils';
import { withTranslation } from '../../lib/translate';
import { Spacer, Subtitle, SmallText, NormalText, Hr } from '../../components/common';
import { version as packageJsonVersion } from '../../../package.json';
import { chevronBack, chevronDown, chevronUp } from 'ionicons/icons';
import defaultImg from '../../assets/images/gray-avatar.png';
import SmallDrawer from './smallDrawer';
import './index.css';
import Basket from '../../lib/basket';
import { getConfig } from '../../appConfig.js';

const collectionIcon = require('../../assets/images/icons/collection.svg');
const deliveryIcon = require('../../assets/images/icons/delivery.svg');
const tableIcon = require('../../assets/images/icons/table.svg');
const logo = require('../../assets/images/logo-main.png');
// const logo5LoyaltyDark = require('../../assets/images/5loyalty-black.svg');
const logo5LoyaltyWhite = require('../../assets/images/5loyalty-white.svg');

const openExternalLink = (url) => window.open(url, '_system', 'location=yes');

const { Device } = Plugins;
const NavItem = withRouter(({ history, item, hideIcon, handleLogout, className, __ }) => {
  const selected = history.location.pathname === item.path;
  return (
    <IonMenuToggle key={item.path} autoHide="false">
      <IonItem
        button
        className={
          'nav-item' + (selected ? ' okx-nav-selected' : '') + (className ? ' ' + className : '')
        }
        onClick={() =>
          item.fn === 'logout'
            ? handleLogout()
            : item.isLink
            ? openExternalLink(item.path)
            : forwardTo(item.path, item.state)
        }
      >
        <div tabIndex="-1"></div>
        {hideIcon ? null : <IonIcon className="nav-icon" slot="start" icon={item.icon} />}
        <NormalText className="nav-label">{__(item.label)}</NormalText>
      </IonItem>
    </IonMenuToggle>
  );
});

class Drawer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      appDeviceVersion: '',
      showPopup: false,
      menuVisible: true,
      logBoxOpen: false,
      profile_image_url: this.props.profile.profile_image_url || defaultImg,
    };
    this.handleLogout = this.handleLogout.bind(this);
    this.handleModal = this.handleModal.bind(this);
  }

  handleLogout() {
    this.props.dispatch(logout());
    const defaultRoute = getDefaultRoute();
    forwardTo(defaultRoute.path);
  }

  handleModal(val) {
    this.setState({ showPopup: val });
  }

  async componentDidMount() {
    const info = await Device.getInfo();
    const appDeviceVersion = info.appVersion;
    this.setState({ appDeviceVersion: appDeviceVersion }, () => {
      this.checkVerison();
    });
  }

  addZeros = (arr = []) =>
    arr.map((i, index) => {
      // e.g. 1.23.8
      // skip first number (app version) (e.g. 1)
      // add zeros only to patch (e.g. 23) or minor (e.g. 8)
      if (index !== 0) {
        return padNumber(i, 3);
      }
      return i;
    });

  isAppVersionValid = (apiVersion = '', appVersion = '') => {
    let ret = true;
    if (apiVersion && appVersion && apiVersion !== '' && appVersion !== '') {
      const apiVersionInt = parseInt(this.addZeros(apiVersion.split('.')).join(''), 10);
      const appVersionInt = parseInt(this.addZeros(appVersion.split('.')).join(''), 10);
      ret = appVersionInt >= apiVersionInt;
      // eslint-disable-next-line no-console
      console.log(
        'APP VERSION:' +
          '\n    isValid:    ' +
          ret +
          '\n    platform:   ' +
          (Capacitor.platform !== 'web' ? 'MOBILE' : 'WEB') +
          '\n    device:     (' +
          typeof appVersion +
          ')-> ' +
          appVersion +
          ' (int: ' +
          appVersionInt +
          ')' +
          '\n    apiversion: (' +
          typeof apiVersion +
          ')-> ' +
          apiVersion +
          ' (int: ' +
          apiVersionInt +
          ')',
      );
    } else {
      // eslint-disable-next-line no-console
      console.error('Skip version checking.');
    }
    return ret;
  };

  checkVerison = () => {
    const { appDeviceVersion } = this.state;
    if (Capacitor.platform !== 'web') {
      if (
        !this.isAppVersionValid(this.props.appVersion, appDeviceVersion) &&
        appDeviceVersion !== ''
      ) {
        this.handleModal(true);
      }
    } else {
      // web version checking
      if (!this.isAppVersionValid(this.props.appVersion, packageJsonVersion)) {
        this.handleModal(true);
      }
    }
  };

  componentDidUpdate(prevProps) {
    if (this.props.appVersion !== prevProps.appVersion) {
      this.checkVerison();
    }

    if (this.props.profile.profile_image_url !== prevProps.profile.profile_image_url) {
      if (this.props.profile.profile_image_url) {
        this.setState({ profile_image_url: this.props.profile.profile_image_url });
      } else {
        this.setState({ profile_image_url: defaultImg });
      }
    }
  }

  toggleMenu = () => {
    this.setState({ menuVisible: !this.state.menuVisible }, () => {
      let drawer = this.state.menuVisible
        ? '--okx-drawer-max-width'
        : '--okx-small-drawer-max-width';
      document.documentElement.style.setProperty('--okx-drawer-width', `var(${drawer})`);
    });
  };

  toggleLogBox = () => {
    const { auth } = this.props;
    const { loggedIn } = auth;
    if (loggedIn) {
      this.setState({ logBoxOpen: !this.state.logBoxOpen });
    } else {
      forwardTo('/login');
    }
  };
  handleViewMenu = () => {
    if (
      (Basket.order_type.includes('collection') && Basket.getOrderTime() === 'Invalid date') ||
      (Basket.order_type.includes('delivery') && !Basket.getDeliveryAddress()) ||
      (Basket.order_type.includes('table') && !Basket.getTableNumber())
    ) {
      Basket.reset();
    }
    forwardTo('/order');
  };
  getDeliveryOptionsIcon = (option) => {
    let icon;
    switch (option.id) {
      case 'delivery':
        icon = deliveryIcon;
        break;
      case 'table':
        icon = tableIcon;
        break;
      default:
        icon = collectionIcon;
    }
    return icon;
  };
  isItemActive = (id) => {
    let active = false;
    switch (id) {
      case 'charter-delivery':
        if (
          this.props.history.location.pathname === '/delivery' ||
          this.props.history.location.pathname === '/delivery-address-add' ||
          this.props.history.location.pathname === '/delivery-address-check' ||
          this.props.history.location.pathname === '/delivery-time'
        ) {
          active = true;
        }
        break;
      case 'table':
        if (this.props.history.location.pathname === '/order-to-table') {
          active = true;
        }
        break;
      case 'collection':
        if (this.props.history.location.pathname === '/click-and-collect') {
          active = true;
        }
        break;
      case 'scheduled-collection':
        if (this.props.history.location.pathname === '/click-and-collect-scheduled') {
          active = true;
        }
        break;
      case 'pick-up-at-counter':
        if (this.props.history.location.pathname === '/pick-up-at-counter') {
          active = true;
        }
        break;
    }
    return active;
  };
  setDeliveryOption = (option) => {
    this.props.dispatch(setDeliveryOption(option));
    // Basket.setDeliveryOption(option);
    // Basket.setOrderType(option.id);
    forwardTo(option.route);
  };
  render() {
    const { auth, __, profile } = this.props;
    const { showPopup, appDeviceVersion, menuVisible, logBoxOpen, profile_image_url } = this.state;
    const { loggedIn } = auth;
    const defaultRoute = getDefaultRoute();
    const groupTitle = [
      { id: 1, title: 'Loyalty and rewards' },
      { id: 2, title: 'Account' },
      { id: 3, title: 'L&B' },
    ];
    const routes = navConfig.routes.filter((route) => !!route.path && !route.notInDrawer);
    return (
      <IonMenu className="drawer-menu" side="start" type="overlay" contentId="main">
        {menuVisible ? (
          <>
            <IonHeader>
              <IonToolbar>
                {/*<IonTitle>{ getConfig().theme.nav.label }</IonTitle>*/}
                <div
                  style={{
                    backgroundImage: `url(${logo})`,
                  }}
                  className="nav-logo"
                  onClick={() => forwardTo(defaultRoute.path)}
                ></div>
                <span
                  onClick={() => this.toggleMenu()}
                  className="icon-placeholder collapse-drawer-icon"
                >
                  <IonIcon color="white" icon={chevronBack} />
                </span>
                <IonButtons slot="end">
                  <IonMenuToggle>
                    <IonButton button clear>
                      <IonIcon slot="icon-only" icon="close" />
                    </IonButton>
                  </IonMenuToggle>
                </IonButtons>
              </IonToolbar>
            </IonHeader>
            <div className="sidebar-menu-content">
              <IonMenuToggle autoHide="false">
                <IonButton
                  color="white"
                  onClick={() => this.handleViewMenu()}
                  className="uppercase drawer-menu-order-button"
                >
                  {__('View our menu')}
                </IonButton>
              </IonMenuToggle>
              <IonList lines="none">
                <div>
                  {getConfig().delivery.filter((d) => !d.isRemoved).length > 0 && (
                    <>
                      <NormalText className="nav-title">{__('Order')}</NormalText>
                      {getConfig().delivery.map((d, index) => (
                        <div key={index}>
                          {!d.isRemoved && (
                            <IonMenuToggle key={index} autoHide="false">
                              <IonItem
                                button
                                disabled={d.isDisabled}
                                className={
                                  'nav-item' + (this.isItemActive(d.id) ? ' okx-nav-selected' : '')
                                }
                                onClick={() => {
                                  Basket.getOrderType();

                                  Basket.reset();
                                  this.setDeliveryOption(d);
                                }}
                              >
                                <IonIcon
                                  className="nav-icon"
                                  slot="start"
                                  icon={this.getDeliveryOptionsIcon(d)}
                                />
                                <NormalText className="nav-label">{__(d.label)}</NormalText>
                              </IonItem>
                            </IonMenuToggle>
                          )}
                        </div>
                      ))}
                    </>
                  )}
                </div>

                {groupTitle.map((group, index) => (
                  <div key={index}>
                    <NormalText className="nav-title">{__(group.title)}</NormalText>
                    {routes.map((route) => {
                      if (route.group === group.id) {
                        return <NavItem __={__} key={route.path} item={route} />;
                      }
                    })}
                  </div>
                ))}
                {getConfig().sidebarLinks?.map((item, index) => (
                  <div key={index}>
                    <NormalText key={index} className=" nav-title">
                      {__(item.title)}
                    </NormalText>
                    <>
                      {item.items.map((el, i) => (
                        <IonItem
                          button
                          key={i}
                          onClick={() => openExternalLink(el.link)}
                          className={'nav-item'}
                        >
                          <span
                            className="external-icon"
                            dangerouslySetInnerHTML={{
                              __html: el.icon,
                            }}
                          ></span>
                          <NormalText className="nav-label">{__(el.label)}</NormalText>
                        </IonItem>
                      ))}
                    </>
                  </div>
                ))}
                <a
                  className="fiveloyalty-logo-wrapper"
                  href="https://www.5loyalty.com"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img alt="logo 5Loyalty white" src={logo5LoyaltyWhite} />
                </a>
                <IonLabel size="small" slot="start">
                  v
                  {Capacitor.platform !== 'web' && appDeviceVersion !== ''
                    ? appDeviceVersion
                    : packageJsonVersion}
                </IonLabel>
              </IonList>
            </div>
          </>
        ) : (
          <SmallDrawer toggleMenu={this.toggleMenu} />
        )}
        <IonAlert
          isOpen={showPopup}
          onDidDismiss={() => this.handleModal(false)}
          header={__('App version')}
          message={__('Your app is out of date. Please update.')}
          buttons={[
            {
              text: __('OK'),
              role: 'cancel',
              cssClass: 'secondary',
              handler: () => this.handleModal(false),
            },
          ]}
        />
      </IonMenu>
    );
  }
}

const stateToProps = (state) => {
  const { auth, profile } = state.profile;
  const { appVersion } = state.common;
  return {
    auth,
    appVersion,
    profile,
  };
};

export default connect(stateToProps)(withRouter(withTranslation(Drawer)));
