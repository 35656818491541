import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import Layout from '../../components/layout';
import Loading from '../../components/spinner';
import { getRewards, getTransactionHistory, sendVoucherCode, setModal } from '../../store/actions';
import { withTranslation } from '../../lib/translate';
import './index.css';
import {
  forwardTo,
  isDefined,
  isEmptyObject,
  isWebConfig,
  validateForm,
  validateProfileData,
} from '../../lib/utils';
import moment from 'moment';
import QRCode from 'qrcode-react';
import {
  FieldError,
  NormalText,
  SmallText,
  Spacer,
  Subtitle,
  Title,
} from '../../components/common';
import { IonButton, IonCol, IonIcon, IonInput, IonItem, IonRow } from '@ionic/react';
import { informationCircleOutline } from 'ionicons/icons';
import { getConfig } from '../../appConfig';
import Basket from '../../lib/basket';
import Modal from '../../components/modal';
import UnlockRewardItems from '../../components/unlockRewardItems';
export const VoucherItem = ({ reward, id, className, action, __ }) => {
  return (
    <div>
      <div
        style={{ backgroundImage: `url(${reward.image})` }}
        className={'voucher' + (className ? ' ' + className : '')}
        onClick={action ? () => action(id) : null}
      ></div>
      <div className="voucher-description">
        <SmallText>{__(reward.name)}</SmallText>
        <SmallText>{`${__('Expires')} ${moment(reward.expiry_date, 'YYYY/MM/DD').format(
          'DD/MM/YY',
        )}`}</SmallText>
      </div>
    </div>
  );
};
export const getLabel = (item) => {
  let label = '';
  const defaultDescriptors = [
    {
      bl_id: '-1',
      label: 'Referral Bonus',
    },
    {
      bl_id: '-2',
      label: 'Sign up Bonus',
    },
    {
      bl_id: '-3',
      label: 'Refunded points',
    },
  ];
  const historyDescriptors = getConfig().history_descriptors || defaultDescriptors;

  const descriptors = historyDescriptors.filter((el) => el.bl_id === item.business_location_id);
  if (descriptors.length > 0) {
    label = descriptors[0].label;
  } else {
    let isPoints = item.is_points;
    let isStamps = item.is_stamps;
    let subLabel = '';
    if (item.stamp_power === 1) {
      subLabel = `${isPoints ? 'Point' : isStamps ? 'Stamp' : ''}`;
    } else if (item.stamp_power !== 1) {
      subLabel = `${isPoints ? 'Points' : isStamps ? 'Stamps' : ''}`;
    }
    if (item.stamp_power < 0) {
      label = `Redeemed ${subLabel}`;
    } else if (item.stamp_power > 0) {
      label = `Earned ${subLabel}`;
    }
  }
  return label;
};
class Loyalty extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      page: null,
      loyaltyFaqModalOpened: false,
      voucherCode: '',
      selectedVoucher: null,
      formErrors: {
        voucherCode: '',
      },
    };
    this.formConfig = {
      voucherCode: { type: 'text', required: true },
    };
  }

  componentDidMount() {
    this.props.dispatch(getRewards());
    this.props.dispatch(getTransactionHistory());
    if (this.props.location && this.props.location.state && this.props.location.state.tab) {
      this.setState({ page: this.props.location.state.tab });
    }
    if (this.props?.location?.state?.openVoucherModal) {
      setTimeout(() => {
        this.viewVoucher(this.props.location.state.openVoucherModal);
      }, 500);
    }
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.location &&
      this.props.location.state &&
      this.props.location.state.tab &&
      prevProps.location &&
      prevProps.location.state &&
      prevProps.location.state.tab &&
      prevProps.location.state.tab !== this.props.location.state.tab
    ) {
      this.setState({ page: this.props.location.state.tab });
    }
  }

  viewVoucher = (id) => {
    const { vouchers } = this.props;

    this.setState({ selectedVoucher: vouchers.find((v) => v.id === id) });
  };
  redeemVoucher = () => {
    const { voucherCode } = this.state;
    if (voucherCode !== '') {
    }
  };
  sendCode = () => {
    const { dispatch, __ } = this.props;
    let formErrors = validateForm(this.formConfig, this.state, __);
    this.setState({ formErrors });
    if (Object.keys(formErrors).length === 0) {
      const { voucherCode } = this.state;
      const data = { code: voucherCode.toUpperCase() };
      this.clearFields();
      //dispatch(redeemGiftVoucher(data));
      dispatch(sendVoucherCode(data, false));
    }
  };
  clearFields = () => {
    this.setState({ voucherCode: '' });
  };
  handlerOfVoucherCode = (e) => {
    const val = e.target.value.toUpperCase();
    this.setState({ voucherCode: val });
  };

  render() {
    const { __, rewards, profile, screenName, vouchers, transactionHistory } = this.props;
    const { loyaltyFaqModalOpened, voucherCode, selectedVoucher, qr_code } = this.state;

    const valid = validateProfileData(profile).isValid;
    let voucherRes = (vouchers || []).filter((data) => {
      if (data.type === 1) {
        return data;
      }
    });
    const points_value_percentage =
      isDefined(getConfig().api_config) && isDefined(getConfig().api_config.points_value_percentage)
        ? getConfig().api_config.points_value_percentage
        : 0;
    return (
      <Loading>
        <Layout
          color="transparent"
          headerWithTitle={true}
          headerTitle={__('Loyalty')}
          noPadding={true}
        >
          <div className="scrollable-y">
            <div className="loyalty-header">
              <div className="loyalty-header-name">
                <Subtitle className="bold loyalty-name">{screenName}</Subtitle>
              </div>
              <div className="loyalty-header-content">
                <div>
                  {points_value_percentage == 0 && profile.available_balance == 0 ? (
                    <div></div>
                  ) : (
                    <div>
                      <Subtitle className="loyalty-points lowercase">
                        {profile.available_balance?.toLocaleString()} {__('pts')}{' '}
                        <IonIcon
                          onClick={() => {
                            this.setState({ loyaltyFaqModalOpened: true });
                          }}
                          icon={informationCircleOutline}
                        />
                      </Subtitle>
                    </div>
                  )}

                  <div>
                    <SmallText className="block is-uppercase">{__('Member since')}</SmallText>
                    <SmallText className="uppercase ">
                      {moment(profile.created_at).format('MM/YY')}
                    </SmallText>
                  </div>
                </div>
                <div style={{ flexDirection: 'column' }}>
                  <div className="qr-code">
                    {isDefined(profile.qr_code) && profile.qr_code !== '' ? (
                      <div className="qr-holder">
                        <QRCode value={profile.qr_code} size={isWebConfig() ? 150 : 200} />
                      </div>
                    ) : (
                      <div>
                        <h5>{__('NO QR CODE')}</h5>
                      </div>
                    )}
                  </div>
                  {/* {isDefined(profile.qr_code) && profile.qr_code !== '' && (hasAddToAppleWallet || hasAddToGoogleWallet) ? <AddToWallet /> : null} */}
                </div>
              </div>
            </div>

            <div
              onClick={() => {
                !valid && this.props.dispatch(setModal('isVerfiedModalOpen', true));
              }}
              className={`loyalty-validation-wrapper ${!valid && 'invalid'}`}
            >
              <NormalText>{valid ? __('Account verified') : __('Account not verified')}</NormalText>
            </div>
            <div className="loyalty-rewards-wrapper">
              <Subtitle className=" loyalty-heading secondary-color">{__('Your rewards')}</Subtitle>
              <UnlockRewardItems
                available_balance={profile.available_balance}
                rewards={rewards}
                qr_code={profile.qr_code}
                __={__}
              />
            </div>

            <div className="loyalty-vouchers-wrapper">
              <FieldError className="field-error" value={__(this.state.formErrors.voucherCode)} />
              <>
                {voucherRes.length > 0 && (
                  <Subtitle className=" loyalty-heading secondary-color">
                    {__('Your vouchers')}
                  </Subtitle>
                )}
                {voucherRes &&
                  voucherRes.map((voucher, index) => {
                    return (
                      <VoucherItem
                        key={'vc-' + index}
                        {...voucher}
                        __={__}
                        action={this.viewVoucher}
                      />
                    );
                  })}
              </>
            </div>
            {this.state.voucherCodeVisible ? (
              <>
                <div className="redeem-voucher-field-wrapper">
                  <div className="input-field-container">
                    <IonItem className="input-field-wrapper" lines="none">
                      <IonInput
                        placeholder={__('Voucher Code')}
                        onIonChange={(e) => this.handlerOfVoucherCode(e)}
                        value={voucherCode}
                      ></IonInput>
                    </IonItem>
                  </div>
                  <IonButton
                    disabled={voucherCode === ''}
                    color="primary"
                    expand="block"
                    onClick={this.sendCode}
                    className="submit-button"
                  >
                    {__('Submit')}
                  </IonButton>
                </div>
              </>
            ) : (
              <>
                <div className="redeem-voucher-btn">
                  <IonButton
                    onClick={() => {
                      this.setState({ voucherCodeVisible: true });
                    }}
                    color="secondary"
                    fill="clear"
                  >
                    {__('Redeem voucher code')}
                  </IonButton>
                </div>
              </>
            )}
            <div className="loyalty-history">
              {transactionHistory.length > 0 && (
                <Subtitle className="loyalty-heading secondary-color">
                  {__('Latest transactions')}
                </Subtitle>
              )}
              <div className="transactions-wrapper box-wrapper">
                {transactionHistory.slice(0, 10).map((el, index) => {
                  return (
                    <div key={index} className="small-padding ">
                      <IonRow key={index}>
                        <IonCol
                          size="8"
                          style={{
                            display: 'flex',
                            justifyContent: 'center',
                            flexDirection: 'column',
                          }}
                        >
                          <SmallText>{__(el.location_name)}</SmallText>
                          <NormalText className="block ">{__(getLabel(el))}</NormalText>
                          <SmallText>
                            {Basket.getDate(el.transaction_date.replace('Z', '')).format(
                              'DD MMM YYYY h:mm a',
                            )}
                          </SmallText>{' '}
                        </IonCol>
                        <IonCol
                          ize="4"
                          style={{
                            display: 'flex',
                            justifyContent: 'flex-end',
                            alignItems: 'center',
                          }}
                        >
                          <div className="transaction-points-wrapper">
                            <NormalText
                              className={
                                el.stamp_power < 0 ? 'bold danger-color' : 'bold success-color'
                              }
                            >
                              {el.stamp_power < 0 ? '-' : '+'}
                              {Math.abs(el.stamp_power)}
                            </NormalText>
                            <SmallText className="">{__('points')}</SmallText>
                          </div>
                        </IonCol>
                      </IonRow>
                    </div>
                  );
                })}
              </div>
            </div>

            <Spacer size={1} />
            {transactionHistory.length > 10 && (
              <IonButton
                fill="clear"
                className="link underlined"
                color="secondary"
                expand="block"
                onClick={() => forwardTo('/history')}
              >
                {__('View all history')}
              </IonButton>
            )}
          </div>
          <Modal
            className="voucher-modal"
            isOpen={selectedVoucher !== null}
            onDidDismiss={() => {
              this.setState({ selectedVoucher: null });
              this.props.history.replace({ state: {} });
            }}
          >
            {selectedVoucher && selectedVoucher.reward ? (
              <div style={{ textAlign: 'center' }}>
                <Title>{__('Voucher Details')}</Title>
                <VoucherItem {...selectedVoucher} __={__} />
                <Spacer size={1} />
                <>
                  {isDefined(profile.qr_code) && profile.qr_code !== '' ? (
                    <div className="qr-holder">
                      <QRCode value={profile.qr_code} size={150} />
                    </div>
                  ) : (
                    <div className="noQrCode">
                      <h5>{__('NO QR CODE')}</h5>
                    </div>
                  )}
                </>
                <Spacer size={1} />
                <div className="voucher-modal-content">
                  <NormalText className="block">{__('Voucher info')}</NormalText>
                  <Spacer size={1} />
                  {selectedVoucher.reward.description ? (
                    <div>
                      <NormalText>{selectedVoucher.reward.description}</NormalText>
                    </div>
                  ) : null}
                </div>
              </div>
            ) : null}
          </Modal>
          <Modal
            className="loyalty-faq-modal"
            isOpen={this.state.loyaltyFaqModalOpened}
            onDidDismiss={() => {
              this.setState({ loyaltyFaqModalOpened: false });
            }}
          >
            <div className="centered">
              <Title>{__('Faq & Support')}</Title>
              <div className="lefted">
                <div dangerouslySetInnerHTML={{ __html: this.props.faq }}></div>
              </div>
            </div>{' '}
          </Modal>
        </Layout>
      </Loading>
    );
  }
}

const stateToProps = (state) => {
  const { profile } = state.profile;
  const { rewards } = state.restaurants;
  const { history } = state.orders;
  let screenName = '';
  if (
    (isDefined(profile) && !isEmptyObject(profile) && profile.first_name) ||
    profile.last_name ||
    (profile.first_name && profile.last_name)
  ) {
    screenName = `${profile.first_name} ${profile.last_name}`;
  }
  return {
    screenName,
    profile,
    rewards: rewards || [],
    transactionHistory: history || [],
    faq: state.common.faq || null,
    vouchers: state.profile.vouchers || [],
    qr_code: state.profile.profile.qr_code,
  };
};

export default connect(stateToProps)(withRouter(withTranslation(Loyalty)));
