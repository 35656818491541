import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from '../../lib/translate';
import Layout from '../../components/layout';
import Loading from '../../components/spinner';
import Mobiscroll from '../../components/mobiscroll';
import Basket from '../../lib/basket';
import {
  Title,
  FieldError,
  SmallText,
  Spacer,
  Sectiontitle,
  NormalText,
  StrongText,
} from '../../components/common';
import {
  IonButton,
  IonItem,
  IonInput,
  IonList,
  IonRadioGroup,
  IonLabel,
  IonRadio,
  IonCheckbox,
  IonAlert,
} from '@ionic/react';
import { isDefined, checkForDeliveryOption } from '../../lib/utils';
import moment from '../../lib/moment';
import {
  setCommonModal,
  getRestaurants,
  getIkentooMenu,
  getIkentooMenusForLocation,
  setDeliveryOption,
  setMyLocation,
} from '../../store/actions';
import { SHOW_TOAST } from '../../store/constants';
import NoData from '../../components/noData';
import { formatDataForTime, getMenusForSelectedTime } from '../clickAndCollect';
import './index.css';
import { getConfig } from '../../appConfig';

const { SelectOption } = Mobiscroll;
const restaurantSelectPlaceholder = 'Select location';

class OrderToTable extends Component {
  state = {
    selectedRestaurant: null,
    error: '',
    isLocationAllowed: false,
    tableNumber: null,
    disableSelectionOption: false,
    isSubmitRestaurantAlertOpen: false,
    isLocationAllowed: false,
    showTableNumber: false,
  };
  getRestautantDataMessage = (restaurant) => {
    return `<p><b>${restaurant?.name}</b></p> \n <p>${restaurant?.address}</p>`;
  };
  componentDidMount() {
    const { restaurants, profile } = this.props;
    Basket.setOrderType('table');
    const deliveryOption = checkForDeliveryOption(
      this.props.deliveryOption ? this.props.deliveryOption : Basket.getDeliveryOption(),
      '/order-to-table',
    );
    if (deliveryOption) {
      this.props.dispatch(getRestaurants());
      this.props.dispatch(setDeliveryOption(deliveryOption));
    }
    if (Basket.getMenu()) {
      this.setState({ selectedIkentooMenu: Basket.getMenu() });
    }
    this.position();
    if (restaurants.length === 1) {
      this.initValueOfSelectedRestaurant(restaurants, profile);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { restaurants } = this.props;

    if (this.state.isLocationAllowed !== prevState.isLocationAllowed) {
      this.props.dispatch(getRestaurants());
      const filteredRestaurants = restaurants.filter(
        (store) =>
          isDefined(store.can_table_order) &&
          isDefined(store.is_published) &&
          store.can_table_order &&
          store.is_published,
      );

      if (filteredRestaurants.length > 0) {
        let byDistance = filteredRestaurants;
        byDistance.sort(function (a, b) {
          return a.distance - b.distance;
        });
        Basket.setRestaurant(byDistance[0]);
        this.selectRestaurant(byDistance[0]);
      }
    }
  }

  initValueOfSelectedRestaurant = (restaurants, profile) => {
    const stores = (restaurants || []).forEach((itm) => {
      if (itm?.id && itm?.name) {
        if (isDefined(itm.can_table_order) && isDefined(itm.is_published)) {
          if (itm.can_table_order && itm.is_published) {
            this.setState(
              { selectedRestaurant: itm.id, pickTime: moment(Date.now()).format('HH:mm') },
              () => {
                Basket.reset(profile?.cardToken);
                Basket.setRestaurant(restaurants.find((restaurant) => restaurant.id === itm.id));
                Basket.setCollectionTime(null);
                Basket.setOrderType('table');
              },
            );
          }
        }
      }
      return null;
    });

    return stores;
  };

  selectRestaurant = (selectedRestaurant) => {
    const { restaurants, profile } = this.props;
    this.setState(
      { selectedRestaurant, pickTime: moment(Date.now()).format('HH:mm'), showTableNumber: true },
      () => {
        Basket.reset(profile.cardToken);
        Basket.setRestaurant(
          restaurants.find((restaurant) => restaurant.id === selectedRestaurant.id),
        );
        if (Basket.getRestaurant()) {
          if (getConfig().general.hasServiceCharge) {
            if (Basket.getRestaurantServiceCharge()) {
              const service_charge_original = Basket.getRestaurantServiceCharge();
              Basket.setServicePercentage(parseInt(service_charge_original[1] * 100));
            } else {
              const defaultServiceCharge = getConfig().general.defaultServiceCharge;
              Basket.setServicePercentage(parseInt(defaultServiceCharge[1] * 100));
            }
          } else {
            Basket.setServicePercentage(0);
          }
        }
        Basket.setCollectionTime(null);
        Basket.setOrderType('table');
      },
    );
  };

  formatDataForSelect = (stores) => {
    const { __ } = this.props;
    let arrForSelect = [];
    stores.forEach((store) => {
      if (isDefined(store.can_table_order) && isDefined(store.is_published)) {
        if (store.can_table_order && store.is_published) {
          arrForSelect.push({ text: store.name, value: store.id });
        }
      }
    });

    if (!this.state.isLocationAllowed) {
      arrForSelect.sort(function (a, b) {
        return a.text < b.text ? -1 : a.text > b.text ? 1 : 0;
      });
    }
    return [{ text: __(restaurantSelectPlaceholder), value: null }, ...arrForSelect];
  };

  chooseMenusForLocation = () => {
    const { __, restaurants, dispatch } = this.props;
    const { selectedRestaurant, tableNumber, pickTime } = this.state;
    if (selectedRestaurant && tableNumber && pickTime) {
      const businessLocationId = selectedRestaurant.business_location_id;
      if (
        !isDefined(selectedRestaurant.order_to_table_menu_id) ||
        selectedRestaurant.order_to_table_menu_id === ''
      ) {
        dispatch({
          type: SHOW_TOAST,
          message: __('This service is currently unavailable!'),
          toastType: 'warning',
        });
        return;
      }
      const menuId = selectedRestaurant.order_to_table_menu_id;
      let cutoffTimeRes = moment().unix();
      Basket.setCutoffTime(cutoffTimeRes);
      Basket.setTableNumber(tableNumber);
      Basket.setMenu(menuId);
      Basket.setPassedOrder(true);
      dispatch(
        getIkentooMenusForLocation(businessLocationId, {
          pickTime,
          json_time_selector: selectedRestaurant ? selectedRestaurant.table_json_time_selector : [],
        }),
      );
    } else if (!selectedRestaurant) {
      this.setState({ error: __('Please select location') });
    } else {
      this.setState({ error: __('Please enter table number') });
    }
  };

  position = async () => {
    /* eslint-disable */
    navigator.geolocation.getCurrentPosition(
      (res) => {
        this.props.dispatch(
          setMyLocation({
            latitude: res?.coords?.latitude,
            longitude: res?.coords?.longitude,
          }),
        );
        this.setState({
          isLocationAllowed: true,
        });
      },
      () => {
        this.setState(
          {
            isLocationAllowed: false,
            showTableNumber: this.state.useSearchParams ? true : false,
          },
          () => {
            this.props.dispatch(setMyLocation({ latitude: null, longitude: null }));
          },
        );
      },
    );
    /* eslint-enable */
  };

  changeIkentooMenus = (event) =>
    this.setState({ selectedIkentooMenu: event.detail.value, error: '' }, () => {
      Basket.setMenu(event.detail.value);
    });

  continueOnMenu = () => {
    const { restaurants, dispatch } = this.props;
    const { selectedIkentooMenu, selectedRestaurant } = this.state;
    if (selectedRestaurant && selectedIkentooMenu) {
      const choosenRestaurant = restaurants.find(
        (restaurant) => restaurant.id === selectedRestaurant,
      );
      const businessLocationId = choosenRestaurant.business_location_id;
      Basket.setPassedOrder(true);
      dispatch(getIkentooMenu(selectedIkentooMenu, businessLocationId));
    } else {
      this.setState({ error: 'Please select location menu' });
    }
  };
  formatDataForSelect = (stores) => {
    const { myLocation } = this.props;
    let arrForSelect = [];
    stores.forEach((store) => {
      if (
        isDefined(store.can_table_order) &&
        isDefined(store.is_published) &&
        store.can_table_order &&
        store.is_published
      ) {
        const currentDT = moment();
        let minDT = currentDT;
        if (store && isDefined(store.order_slot_lead_time)) {
          minDT.add(store.order_slot_lead_time, 'minutes');
        }
        const timeData = formatDataForTime(store, minDT, store.id, false, true, []);
        store.opened =
          timeData.length === 1 && timeData[0].text.toLowerCase() === 'closed' ? 'Closed' : 'Open';
        arrForSelect.push(store);
      }
    });
    if (this.state.isLocationAllowed || (myLocation?.latitude && myLocation?.longitude)) {
      arrForSelect.sort(function (a, b) {
        return a.distance < b.distance ? -1 : a.distance > b.distance ? 1 : 0;
      });
    } else {
      arrForSelect.sort(function (a, b) {
        return a.name < b.name ? -1 : a.name > b.name ? 1 : 0;
      });
    }
    return arrForSelect;
  };
  render() {
    const { __, restaurants, isChooseMenuModalOpen, ikentooMenusForLocation } = this.props;
    const {
      error,
      selectedIkentooMenu,
      pickTime,
      selectedRestaurant,
      showTableNumber,
    } = this.state;
    const stores = restaurants || [];
    const animationMenuClass = isChooseMenuModalOpen ? 'show-up' : '';
    const store = restaurants.find((restaurant) => restaurant.id === selectedRestaurant) || null;
    const menus = getMenusForSelectedTime(
      ikentooMenusForLocation,
      pickTime,
      store ? store.table_json_time_selector : [],
    );
    const formattedStores = this.formatDataForSelect(stores);
    return (
      <Loading transparent>
        <Layout headerWithTitle headerTitle={__('Order To Table')} color="transparent">
          <div className="flex-row-wrapper absolute-content">
            {!showTableNumber ? (
              <>
                <div className="click-and-collect-title ">
                  <Title className="web-only">{__('Order To Table')}</Title>
                  <NormalText>{__('Select a restaurant to collect your order')}.</NormalText>
                </div>
                <Spacer size={1} />
                <div className="click-and-collect-locations">
                  <IonList lines="none" className="box-wrapper">
                    {formattedStores.map((store) => (
                      <div className="click-collect-items">
                        <IonItem
                          disabled={store.opened === 'Closed'}
                          onClick={() => this.setState({ selectedRestaurant: store })}
                          lines="none"
                        >
                          <IonLabel>
                            <StrongText>{__(store.name)}</StrongText>
                            <NormalText className="block">{__(store.address)}</NormalText>
                          </IonLabel>
                          <IonCheckbox
                            checked={selectedRestaurant && store.id === selectedRestaurant.id}
                            slot="start"
                            color="primary"
                          />
                        </IonItem>
                      </div>
                    ))}
                  </IonList>
                </div>
                <Spacer />
                <div className="click-collect-button">
                  <IonButton
                    disabled={!selectedRestaurant}
                    expand="block"
                    color="primary"
                    onClick={() => this.setState({ isSubmitRestaurantAlertOpen: true })}
                  >
                    {__('Continue')}
                  </IonButton>
                </div>
              </>
            ) : (
              <>
                <Title className="click-collect-title web-only">{__('Order To Table')}</Title>
                <NormalText className="block delivery-option-description">
                  {__('Confirm your location and table')}.
                </NormalText>
                <Spacer size={1} />
                <div className="click-collect-dialog-content">
                  <IonList className="shadowed-box-light no-padding box-wrapper">
                    <IonItem className="default-padding " key={selectedRestaurant?.id} lines="none">
                      <div tabIndex="-1"></div>
                      <IonCheckbox color="primary" slot="start" checked />
                      <IonLabel className="ion-text-wrap" color="dark">
                        <StrongText>{selectedRestaurant?.name}</StrongText>
                        <NormalText className="block">{selectedRestaurant?.address}</NormalText>
                      </IonLabel>
                    </IonItem>
                  </IonList>
                  <div>
                    <IonButton
                      expand="block"
                      fill="clear"
                      color="secondary"
                      className="link underlined"
                      onClick={() =>
                        this.setState({
                          showTableNumber: false,
                        })
                      }
                    >
                      {__('At a different location?')}
                    </IonButton>
                  </div>
                  <Spacer />

                  <div className="input-field-container ">
                    <NormalText>{__('Enter table number')}</NormalText>
                    <IonItem lines="none" class="input-field-wrapper">
                      <IonInput
                        className="table-number-input"
                        onIonChange={(e) => this.setState({ tableNumber: e.target.value })}
                        value={this.state.tableNumber}
                        type="text"
                        inputmode="tel"
                      />
                    </IonItem>
                  </div>
                </div>
                <div className="flex-min">
                  <IonButton
                    disabled={this.state.tableNumber ? false : true}
                    expand="block"
                    color="primary"
                    className={this.state.continueButtonClicked ? 'unclicked' : ''}
                    onClick={() =>
                      this.setState({ isSubmitRestaurantAlertOpen: true, forwardToOrdering: true })
                    }
                  >
                    {__('Continue')}
                  </IonButton>
                </div>
              </>
            )}
          </div>
          {/* <div className="flex-row-wrapper absolute-content">
            <div className="scrollable-y">
              <Title className="okx-font-secondary">
                {deliveryOption ? deliveryOption.label : ''}
              </Title>
              <NormalText>
                {__(
                  'Enter your table number and your order will be brought to you as soon as possible. This service is available for in-store use only.',
                )}
              </NormalText>
              <Spacer size={2} />
              <label className="select-picker-label okx-font-secondary" htmlFor="demo-non-form">
                {__('Location')}
              </label>
              <SelectOption
                display="center"
                onSet={(e, a) => this.selectRestaurant(e, a)}
                data={this.formatDataForSelect(stores)}
                label="Location"
                value={this.state.selectedRestaurant}
                inputStyle="box"
                placeholder={__(restaurantSelectPlaceholder)}
                setText={__('OK')}
                cancelText={__('Cancel')}
                disabled={restaurants.length <= 1 ? true : false}
              />
              <br />
              <label className="time-picker-label okx-font-secondary" htmlFor="demo-non-form">
                {__('Table Number')}
              </label>
    
              <IonInput
                className="table-number-input"
                onIonChange={(e) => this.setState({ tableNumber: e.target.value })}
                value={this.state.tableNumber}
                type="text"
                inputmode="tel"
                placeholder="Enter table number"
              />
              {error ? (
                <IonItem>
                  <div tabIndex="-1"></div>
                  <FieldError className="field-error" value={__(error)} />
                </IonItem>
              ) : null}
            </div>
            <div className="flex-min">
              <IonButton
                disabled={this.state.tableNumber ? false : true}
                expand="block"
                color="secondary"
                className="uppercase okx-font-secondary"
                onClick={() => this.chooseMenusForLocation()}
              >
                {__('Continue')}
              </IonButton>
            </div>
          </div>
          <div
            className="click-collect-pickers-backdrop"
            style={{ display: isChooseMenuModalOpen ? '' : 'none' }}
            onClick={() => this.props.dispatch(setCommonModal('isChooseMenuModalOpen', false))}
          ></div>
          <div className={`click-collect-dialog ${animationMenuClass}`}>
            <div className="click-collect-dialog-layout sc-ion-modal-md">
              <div className="click-collect-dialog-header">
                <h3>{__('Choose menu')}</h3>
              </div>
              <div
                className="click-collect-dialog-closer"
                style={{ position: 'absolute', right: 0, top: 0 }}
                onClick={() => this.props.dispatch(setCommonModal('isChooseMenuModalOpen', false))}
              >
                <ion-icon
                  name="close"
                  role="img"
                  className="md hydrated"
                  aria-label="close"
                ></ion-icon>
              </div>
              <div className="click-collect-dialog-content">
                <IonList lines="none">
                  <IonRadioGroup onIonChange={this.changeIkentooMenus} value={selectedIkentooMenu}>
                    {menus.length === 0 ? (
                      <NoData />
                    ) : (
                      menus.map((menu) => {
                        const { ikentooMenuId, menuName } = menu;
                        return (
                          <IonItem key={ikentooMenuId} lines="full">
                            <div tabIndex="-1"></div>
                            <IonLabel className="ion-text-wrap">
                              <Sectiontitle>{menuName}</Sectiontitle>
                            </IonLabel>
                            <IonRadio color="tertiary" slot="start" value={ikentooMenuId} />
                          </IonItem>
                        );
                      })
                    )}
                  </IonRadioGroup>
                </IonList>
              </div>
              <div className="click-collect-dialog-action">
                {error ? (
                  <IonItem>
                    <div tabIndex="-1"></div>
                    <FieldError className="field-error" value={__(error)} />
                  </IonItem>
                ) : null}
                <IonButton
                  disabled={pickTime && menus.length > 0 ? false : true}
                  expand="block"
                  color="secondary"
                  className="uppercase okx-font-secondary"
                  onClick={() => this.continueOnMenu()}
                >
                  {__('Continue')}
                </IonButton>
              </div>
            </div>
          </div> */}
          <IonAlert
            isOpen={this.state.isSubmitRestaurantAlertOpen}
            onDidDismiss={() => this.setState({ isSubmitRestaurantAlertOpen: false })}
            header={__('Please confirm you are in this location')}
            message={__(this.getRestautantDataMessage(selectedRestaurant))}
            buttons={[
              {
                text: this.state.forwardToOrdering ? __('Change') : __('Cancel'),
                role: 'cancel',
                cssClass: 'secondary',
                handler: () =>
                  this.state.forwardToOrdering
                    ? this.setState({ showTableNumber: false, forwardToOrdering: false })
                    : this.setState({ isSubmitRestaurantAlertOpen: false }),
              },
              {
                text: __('Confirm'),
                role: 'submit',
                cssClass: 'secondary',
                handler: () =>
                  this.state.forwardToOrdering
                    ? this.chooseMenusForLocation()
                    : this.selectRestaurant(selectedRestaurant),
              },
            ]}
          />
        </Layout>
      </Loading>
    );
  }
}

const stateToProps = (state) => {
  const { deliveryOption } = state.orders;
  const { restaurants, ikentooMenusForLocation } = state.restaurants;
  const { isChooseMenuModalOpen } = state.common;
  return {
    deliveryOption,
    restaurants: restaurants || [],
    profile: state.profile.profile,
    isChooseMenuModalOpen: isChooseMenuModalOpen,
    ikentooMenusForLocation: ikentooMenusForLocation || [],
  };
};

export default connect(stateToProps)(withTranslation(OrderToTable));
