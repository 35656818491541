import React from 'react';
import {
  IonList,
  IonItem,
  IonRadioGroup,
  IonRadio,
  IonLabel,
  IonButton,
  IonIcon,
} from '@ionic/react';
import Loading from '../../components/spinner';
import Layout from '../../components/layout';
import { withTranslation } from '../../lib/translate';
import {
  Title,
  SmallText,
  Spacer,
  Sectiontitle,
  NormalText,
  StrongText,
} from '../../components/common';
import { connect } from 'react-redux';
import Basket from '../../lib/basket';
import {
  forwardTo,
  insertIntoArray,
  goBack /*, isEmptyObject*/,
  isWebConfig,
} from '../../lib/utils';
import { Capacitor } from '@capacitor/core';
import { getConfig } from '../../appConfig';
import DrawPayButton from '../../components/drawPayButton';
import CheckoutPay from './CheckoutPay';
import './index.css';
import { arrowForward, cardOutline } from 'ionicons/icons';

const { getMobile, setMobile } = Basket;

const isWeb = () => Capacitor.platform === 'web';
class Checkout extends React.Component {
  constructor(props) {
    super(props);
    if (!getMobile() && this.props.profile && this.props.profile.mobile) {
      setMobile(this.props.profile.mobile);
    }
    this.state = {
      selectedCard: null,
      selectedJudopayCard: null,
    };
  }

  componentDidMount() {
    const { profile } = this.props;
    const { google } = window;
    if (getConfig().payment === 'judopay') {
      let judoPayCardsLen = profile.judo_pay_cards.length - 1;
      this.changeSelectedPaymentCard(judoPayCardsLen);
    }
    if (profile.cardToken) {
      this.changeSelectedPaymentCard(profile.cardToken);
    }
    window.googlePayClient = new google.payments.api.PaymentsClient({ environment: 'PRODUCTION' });
  }

  componentDidUpdate(prevProps) {
    const { profile } = this.props;
    let judoPayCardsLen = profile.judo_pay_cards.length - 1;
    if (prevProps.profile.cardToken !== profile.cardToken) {
      this.changeSelectedPaymentCard(profile.cardToken);
    }

    if (
      getConfig().payment === 'judopay' &&
      prevProps.profile.judo_pay_cards.length !== profile.judo_pay_cards.length
    ) {
      this.changeSelectedPaymentCard(judoPayCardsLen);
    }
  }

  changeSelectedPaymentCard = (cardId) =>
    this.setState({ selectedCard: cardId }, () => {
      Basket.changeSelectedCard(cardId);
      // this.props.dispatch(updateProfile({ cardToken: cardId }, true)) // reason: this line has commented, because we're updating user profile on the BO
    });

  backHandler = () => {
    if (
      this.props.location &&
      this.props.location.state &&
      this.props.location.state.skipContactDetails
    ) {
      forwardTo('/order', { skipBackToThePreviousPage: true });
    } else if (this.props.location.pathname === '/checkout') {
      forwardTo('/order', { skipBackToThePreviousPage: true });
    } else {
      goBack();
    }
  };

  render() {
    const { __, cards, clientSecret, dispatch, location, profile } = this.props;
    return (
      <Loading transparent>
        <Layout
          color="transparent"
          headerWithTitle
          headerTitle={__('Checkout')}
          backHandler={this.backHandler}
        >
          <div className="flex-row-wrapper absolute-content">
            <Title className="web-only">{__('Checkout')}</Title>

            <Spacer size={1} />
            <div className="scrollable-y">
              <IonList lines="full">
                {getConfig().payment === 'judopay' ? (
                  <IonRadioGroup
                    onIonChange={(e) => this.changeSelectedPaymentCard(e.detail.value)}
                    value={this.state.selectedCard}
                  >
                    {cards.map((card, id) => {
                      const { cardLastFour, cardExpiry, cardType, name } = card;
                      let cardEx = insertIntoArray(cardExpiry, 2, '/');
                      return (
                        <IonItem key={id}>
                          <div tabIndex="-1"></div>
                          <IonLabel className="ion-text-wrap">
                            <NormalText color="primary" className="single-item bold">
                              {name || ''}
                            </NormalText>
                            <Sectiontitle className="no-margin">
                              •••• •••• •••• {cardLastFour}
                            </Sectiontitle>
                            <SmallText color="primary">
                              {__(cardType)} - {cardEx}
                            </SmallText>
                          </IonLabel>
                          <IonRadio
                            slot="start"
                            color="tertiary"
                            value={id + ''}
                            onIonSelect={() => {
                              this.setState({ selectedCard: id }, () => {
                                Basket.changeSelectedCard(id);
                              });
                            }}
                          />
                        </IonItem>
                      );
                    })}
                  </IonRadioGroup>
                ) : (
                  <IonRadioGroup
                    onIonChange={(e) => this.changeSelectedPaymentCard(e.detail.value)}
                    value={this.state.selectedCard}
                  >
                    {cards.map((card) => {
                      const { id, last4, brand, exp_month, exp_year, name } = card;
                      return (
                        <IonItem lines="none" className="box-content checkout-card" key={id}>
                          <IonIcon slot="start" icon={cardOutline}></IonIcon>

                          <IonLabel className="ion-text-wrap">
                            <NormalText className="single-item">{name}</NormalText>
                            <StrongText className=" block">**** **** **** {last4}</StrongText>
                            <NormalText>
                              {__(brand)} - {exp_month}/{exp_year}
                            </NormalText>
                          </IonLabel>
                          <IonRadio
                            color={isWebConfig() ? 'primary' : 'white'}
                            slot="start"
                            value={id}
                            onIonSelect={() => {
                              this.setState({ selectedCard: id }, () => {
                                Basket.changeSelectedCard(id);
                              });
                            }}
                          />
                        </IonItem>
                      );
                    })}
                  </IonRadioGroup>
                )}
              </IonList>
              {cards.length == 0 && (
                <IonItem lines="none" className="cards-list-card box-content no-cards-box">
                  <div className="cards-list-card-image">
                    <IonIcon icon={cardOutline}></IonIcon>
                  </div>
                  <IonLabel className="ion-text-wrap">
                    <NormalText className="single-item">{__('No payment cards added')}</NormalText>
                  </IonLabel>
                </IonItem>
              )}
              <IonButton
                color="primary"
                expand="block"
                onClick={() => forwardTo('/card-add', { addCardFromCheckout: true })}
              >
                {__('Add New Payment Card')}
              </IonButton>
            </div>
            <div className="flex-min">
              {/* <IonItem>
							</IonItem> */}
              {isWeb() && getConfig().services.payments.webPay ? (
                <CheckoutPay
                  clientSecret={clientSecret}
                  dispatch={dispatch}
                  __={__}
                  location={location}
                  profile={profile}
                />
              ) : (
                <DrawPayButton __={__} googlePayClient={window.googlePayClient} />
              )}
              <Spacer size={1} />
              {cards.length > 0 && (
                <IonButton
                  color="primary"
                  disabled={cards.length === 0}
                  onClick={() => Basket.createOrder()}
                  expand="block"
                >
                  {__('Pay')}
                  {' ' + Basket._getTotal()}
                </IonButton>
              )}
              {/* { this.drawPayButton() } */}
              {/* <div className="centered"><IonButton color="primary" fill="clear" className="link" onClick={ this.backHandler }>{ __('Cancel') }</IonButton></div> */}
            </div>
          </div>
        </Layout>
      </Loading>
    );
  }
}

const mapStateToProps = (store) => {
  return {
    cards: store.orders.cards || [],
    clientSecret: store.orders.clientSecret,
    profile: store.profile.profile || {},
  };
};

export default connect(mapStateToProps)(withTranslation(Checkout));
