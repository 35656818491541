import { IonAlert, IonButton, IonCol } from '@ionic/react';
import React from 'react';
import { connect } from 'react-redux';
import { getConfig } from '../../appConfig';
import AmountPaid from '../../components/amountPaid';
import Box, { BoxHeader } from '../../components/box';
import { SmallText, Spacer, StrongText, Title } from '../../components/common';
import Layout from '../../components/layout';
import OrderContent from '../../components/orderContent';
import Loading from '../../components/spinner';
import Basket, { createNewBasketInstance } from '../../lib/basket';
import moment from '../../lib/moment';
import { withTranslation } from '../../lib/translate';
import { forwardTo, visaEndingDrawText } from '../../lib/utils';
import { showToast } from '../../store/actions';
import { checkCancelOrder, setOrdersProp } from '../../store/orders/actions';
import './index.css';

class HistoryDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isCancel: false,
      orderId: null,
      restaurantId: null,
      orderArr: props.location.state ? props.location.state.order : null,
    };
  }

  componentDidMount() {
    const { location } = this.props;
    const orderArr = location?.state?.order;
    const now = moment();
    let cutoffTime = orderArr ? orderArr.cutoff_time : now;
    let cutoffTimeMoment = new moment(cutoffTime, 'YYYY-MM-DD HH:mm:ss');
    const utcOffset = cutoffTimeMoment.utcOffset();
    cutoffTimeMoment = cutoffTimeMoment.add('minutes', utcOffset);
    if (now.isBefore(cutoffTimeMoment)) {
      if (orderArr.status !== 'REFUNDED') {
        this.setState({
          isCancel: true,
          orderId: orderArr.id,
          restaurantId: orderArr.restaurant_id,
        });
      }
    }
  }

  backHandler = () => {
    this.setState({ orderId: null, isCancel: false, restaurantId: null });
    forwardTo('/history', { tab: 'order' });
  };

  formatPaymentString = (str) => {
    let splitedStr = str.split(' ').splice(2).join(' ');
    return splitedStr;
  };

  cancelOrder = () => {
    const { orderId, isCancel, restaurantId } = this.state;
    if (isCancel) {
      this.props.dispatch(checkCancelOrder(orderId, restaurantId));
      this.setState({ orderId: null, isCancel: false, restaurantId: null });
    }
  };

  handleCancelOrderModal = (flag) => {
    this.props.dispatch(setOrdersProp('cancelOrderModal', flag));
  };

  drawContentTitle = (__, deliveryOption, id, orderType) => {
    if (orderType === 'Outpost Drop-Off') {
      return (
        <Title>
          {__('Drop Point')} {__('Order')} #{id}
        </Title>
      );
    } else if (orderType === 'Delivery') {
      return (
        <Title>
          <strong>
            {__('Delivery')} {__('Order')} #{id}
          </strong>
        </Title>
      );
    } else if (orderType === 'Table') {
      return (
        <Title>
          <strong>
            {__('At Table')} {__('Order')} #{id}
          </strong>
        </Title>
      );
    } else if (orderType === 'pick-up-at-counter') {
      return (
        <Title>
          <strong>
            {__('Pickup at Counter')} #{id}
          </strong>
        </Title>
      );
    } else {
      return (
        <Title>
          {/* __(orderType) */} {__('Order')} #{id}
        </Title>
      );
    }
  };
  orderAgain = () => {
    const selectedRestaurant = this.props.restaurants.filter(
      (restaurant) => restaurant.id === this.state.orderArr.restaurant_id,
    );
    if (selectedRestaurant.length > 0) {
      Basket.reset();
      let orderLabel = Basket.getOrderType(this.state.orderArr);
      let option = (getConfig().delivery || []).find((d) => d.id === orderLabel);
      Basket.setDeliveryOption(option);
      forwardTo(option.route, { selectedRestaurant: this.state.orderArr });
    } else {
      this.props.dispatch(showToast('This restaurant is no longer receiving orders.', 'warning'));
    }
  };

  drawContent = (__, order, orderType, basketInstance, status) => {
    if (!order?.is_gift) {
      if (orderType === 'Click & Collect') {
        return (
          <>
            <div className="box-content order-content-details">
              <div>
                <StrongText>{__('Collect from')}</StrongText>
                <StrongText className="block">{order.restaurant_name}</StrongText>
                <SmallText className="block">
                  {__('Collect at')}:{' '}
                  {basketInstance.formatOrderTime(true, 'DD MMM YYYY [at] h:mm a')}
                </SmallText>
                <SmallText className="block">
                  {__('Order placed on')}:{' '}
                  {moment(order.created_at).format('DD MMM YYYY [at] h:mm a')}
                </SmallText>
              </div>
              <div className="order-status-wrapper">
                <div>{status && __(status)}</div>
              </div>
            </div>
          </>
        );
      } else if (orderType === 'Delivery') {
        return (
          <>
            <div className="box-content order-content-details">
              <div>
                <StrongText>{__('Delivery from')}</StrongText>
                <StrongText className="block">{order.restaurant_name}</StrongText>
                <SmallText className="block">
                  {__('Order placed on')}:{' '}
                  {moment(order.created_at).format('D MMM YYYY [at] h:mm a')}
                </SmallText>
                <SmallText className="block">
                  {__('Delivery at')}: {basketInstance.formatOrderTime(true)}
                </SmallText>
              </div>
              <div className="order-status-wrapper">
                <div>{status && __(status)}</div>
              </div>
            </div>
          </>
        );
      } else if (orderType === 'Outpost Drop-Off') {
        return (
          <>
            <div className="box-content order-content-details">
              <div>
                <StrongText>{__('Drop-off at')}</StrongText>
                <StrongText className="block">{order.restaurant_name}</StrongText>
                <SmallText className="block">
                  {__('Order placed on')}:{' '}
                  {moment(order.created_at).format('ddd DD MMMM YYYY [at] LT')}
                </SmallText>
                <SmallText className="block">
                  {__('Drop-off Time')}: {basketInstance.formatOrderTime(true)}
                </SmallText>
              </div>
              <div className="order-status-wrapper">
                <div>{status && __(status)}</div>
              </div>
            </div>
          </>
        );
      } else if (orderType === 'Table') {
        return (
          <>
            <div className="box-content order-content-details">
              <div>
                <StrongText>{__('Ordered at')}</StrongText>
                <StrongText className="block">{order.restaurant_name}</StrongText>
                <StrongText className="block">
                  {__('Table Number')}-{order.table_name}
                </StrongText>
                <SmallText className="block">
                  {__('Order placed on')}:{' '}
                  {moment(order.created_at).format('ddd DD MMMM YYYY [at] LT')}
                </SmallText>
              </div>
              <div className="order-status-wrapper">
                <div>{status && __(status)}</div>
              </div>
            </div>
          </>
        );
      } else if (orderType === 'pick-up-at-counter') {
        return (
          <>
            <div className="box-content order-content-details">
              <div>
                <StrongText>{__('Collect from')}</StrongText>
                <StrongText className="block">{order.restaurant_name}</StrongText>
                <SmallText className="block">
                  {__('Order placed on')}:{' '}
                  {moment(order.created_at).format('DD MMM YYYY [at] h:mm a')}
                </SmallText>
              </div>
              <div className="order-status-wrapper">
                <div>{status && __(status)}</div>
              </div>
            </div>
          </>
        );
      }
    }
    if (orderType === 'scheduled-collection') {
      return (
        <>
          <div className="box-content order-content-details">
            <div>
              <StrongText>{__('Collect from')}</StrongText>
              <StrongText className="block">{order.restaurant_name}</StrongText>
              <SmallText className="block">
                {__('Collect at')}:{' '}
                {basketInstance.formatOrderTime(true, 'DD MMM YYYY [at] h:mm a')}
              </SmallText>
              <SmallText className="block">
                {__('Order placed on')}:{' '}
                {moment(order.created_at).format('DD MMM YYYY [at] h:mm a')}
              </SmallText>
            </div>
            <div className="order-status-wrapper">
              <div>{status && __(status)}</div>
            </div>
          </div>
        </>
      );
    } else {
      return <></>;
    }
  };

  render() {
    const { __, location, cards } = this.props;
    const order = location.state.order;
    const { id, status } = order;
    const basketInstance = createNewBasketInstance();
    basketInstance.recreateOrder(order);
    const orderType = basketInstance.getOrderType();
    const orderCompletePage = false;
    let paymentMetod = basketInstance.formatPaymentMethod(this.props.cards, __, orderCompletePage);
    const orderTypeTitle =
      basketInstance.getOrderType() === 'Click & Collect'
        ? 'Collection'
        : basketInstance.getOrderType();
    const hasCancelOrder = getConfig().appType.hasCancelOrder;
    const isOldLayoutOfConfirmationScreens = false;
    const headerLabel = `${__('Order')} ${`#${id}`}`;

    return (
      <Loading>
        <Layout
          headerTitle={headerLabel}
          headerWithTitle={true}
          color="transparent"
          backHandler={this.backHandler}
        >
          <div className="absolute-content history-details">
            <div className="scrollable-y">
              {!isOldLayoutOfConfirmationScreens ? (
                <>
                  <Title className="web-only">
                    {this.drawContentTitle(__, Basket.getDeliveryOption(), id, orderTypeTitle)}
                  </Title>
                  <>
                    {this.drawContent(__, order, orderType, basketInstance, status)}
                    <Spacer size={2} />
                    <OrderContent basketInstance={basketInstance} type="orderHistory" />
                    <Spacer size={2} />
                    <AmountPaid order={order} cards={this.props.cards} />
                    <Spacer size={2} />
                  </>
                  {/* </Box> */}
                  {/* {orderType !== 'Click & Collect' && ['PAID', 'PARSED', 'NEW', 'new'].indexOf(order.status) !== -1 && this.state.isCancel && hasCancelOrder ? (
										<IonButton expand="block" fill="clear" className="link underlined" color="primary" onClick={() => this.handleCancelOrderModal(true)}>
											{__('Cancel this order')}
										</IonButton>
									) : null} */}
                </>
              ) : (
                <>
                  <div className="order-status">{__(status)}</div>
                  {this.drawContentTitle(__, Basket.getDeliveryOption(), id, orderTypeTitle)}
                  {isOldLayoutOfConfirmationScreens ? (
                    <div className="order-status-payment-method">
                      <IonCol size="5" className="mr-10">
                        <SmallText tag="strong" className="bold">
                          {__('Payment Method')}:
                        </SmallText>
                      </IonCol>
                      <IonCol>
                        <SmallText color="primary" className="thiner-text">
                          {visaEndingDrawText(paymentMetod)}
                        </SmallText>
                      </IonCol>
                    </div>
                  ) : null}
                  <Spacer size={1} />
                  <Box>
                    <BoxHeader>
                      {this.drawContent(__, order, orderType, basketInstance, orderCompletePage)}
                    </BoxHeader>
                    <Spacer size={2} />
                    <StrongText>{__('Items Ordered')}</StrongText>
                    <OrderContent basketInstance={basketInstance} type="orderHistory" />
                    <AmountPaid order={order} cards={cards} />
                  </Box>
                </>
              )}
              {/* <div>
                {!order.is_gift && (
                  <IonButton
                    color="primary"
                    expand="block"
                    fill="solid"
                    onClick={() => this.orderAgain()}
                    className="reorder-btn"
                  >
                    {__('Order it again')}
                  </IonButton>
                )}
              </div> */}
            </div>
          </div>
        </Layout>
        <IonAlert
          isOpen={this.props.cancelOrderModal === true}
          onDidDismiss={() => this.handleCancelOrderModal(false)}
          header={__('Confirm')}
          message={__('Do you want to cancel this order?')}
          buttons={[
            {
              text: __('Cancel'),
              role: 'cancel',
              cssClass: 'secondary',
            },
            {
              text: __('Remove'),
              handler: () => this.cancelOrder(),
            },
          ]}
        />
      </Loading>
    );
  }
}

const mapStateToProps = (store) => {
  const orders = store.orders;
  const { cancelOrderModal } = orders;
  const { restaurants } = store.restaurants;
  let lastOrder = null;
  const orderHistory = orders.orderHistory;
  if (orderHistory && orderHistory.length > 0) {
    lastOrder = orderHistory[0];
  }
  return {
    cards: orders.cards || [],
    lastOrder,
    restaurants: restaurants || [],
    cancelOrderModal,
  };
};

export default connect(mapStateToProps)(withTranslation(HistoryDetails));
